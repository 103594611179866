import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { auth } from 'firebase';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MercadoPagoService } from 'src/app/shared/services/mercado-pago.service';
interface Window {
  location: any;
  $MPC: any
}
declare var window: Window;
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {
  // TODO you should create an interface for each variable that indicates its attributes
  public form: FormGroup;
  public userData : any;
  public uId : any;
  public idRelatedFamily;
  public paiyng;
  public price;
  public loadingStep = true;
  public step = 1;
  public loading : boolean;
  public invalidCode = false
  public code;
  // mercado pago
  init_point: any;
  collection_status: any;
  collection_id: any;

  isRouterChange = false;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private mercadoPagoService: MercadoPagoService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) {

    this.authService.getUserData();

    this.price = this.formatNumber(10000);
    this.idRelatedFamily = route.snapshot.params.identify;
    if(route.snapshot.params.identify){this.step = route.snapshot.params.identify}
    console.log(this.idRelatedFamily)
    
    this.form = this.newForm(fb);
    this.loading = false;
    this.paiyng = false;

    this.step = parseInt( this.route.snapshot.paramMap.get('step'));
    if(!this.authService.isLoggedIn){
      this.step = 1;
      this.loadingStep = false;
    }

    this.router.events.subscribe(res => {

      if (res instanceof NavigationEnd && res.urlAfterRedirects && res.urlAfterRedirects.length > 10 && !this.isRouterChange){
          if(res.id === 1){
            console.log("Asdasd")
            this.isRouterChange = true;
              this.route.queryParams.subscribe( (params) => {
                setTimeout(() => {
                    this.collection_status = params['collection_status'];
                  if(this.collection_status){
                    this.loading = true
                    this.collection_id = params['collection_id'];
                    this.loadingStep = false;
                    const paymentInfo = {
                      uId: this.authService.getUser(),
                      relatedFamily: this.route.snapshot.params.identify,
                      mercadoPago: {
                      collection_id : this.collection_id,
                      collection_status: this.collection_status,
                      }
                    };
                    this.sendPaymentInfo(paymentInfo);
                  }else{
                    console.log(this.step)
                    this.loadingStep = false
                  }
                }, 3000);
              });
            
          }else{
            const url = res.urlAfterRedirects.split(';');
            this.step = Number(url[1].split('=')[1]);
            this.isRouterChange = true;
            this.loadingStep = false;
          }
      }else{
        if(res instanceof NavigationEnd && res.urlAfterRedirects.length < 10 && this.authService.isLoggedIn){
          this.loadingStep = true
          setTimeout(() => {
            this.userService.getUserLastStep(this.authService.getUser())
            .subscribe ( result => {
              this.getStep(result)
              var resultado = result;
              this.code = result.code
              this.loadingStep = false;
            },err =>{
              this.step = 1;
              this.loadingStep=false
            });         
          }, 5000);        }
      } 
    });

  }

  ngOnInit() {
   }

  public nextStep(){
    this.step += 1;
  }

  private newForm(fb): FormGroup {
    return fb.group({
        name: fb.control('', [Validators.required,Validators.minLength(6)]),
        code: fb.control('')
    });
  }
  public sendData(){

    this.loading = true;
    this.uId = this.authService.getUser();

    this.code = this.form.controls.code.value;

    if(!this.code){
      this.code = 'none'
    } else{
      this.price = this.formatNumber(8000)
    }
    this.userData = {
      relatedFamily : {
        fullName: this.form.controls.name.value,
      },
      uId : this.uId,
      code : this.code
    }

    this.userService.postUserDataRelatedFamily(this.userData)
      .subscribe( result => {
        this.loading = false;
        this.idRelatedFamily = result.idRelatedFamily;
        this.step += 1;
        this.router.navigate(['/sign-in', { step: this.step, identify:  this.idRelatedFamily}]);

        }, error => {
          console.log(error.error)
          this.loading = false;
          this.invalidCode = true
          this.code = undefined
          this.price = this.formatNumber(10000)
        });
  }

  public goToEnrollment(){
    const uIdRealtedFamilyRouting = this.route.snapshot.paramMap.get('identify');
    this.router.navigate(['/enrollment/'+ uIdRealtedFamilyRouting ]);
  }

  public goBack(){
    if (this.step === 2) {this.paiyng = false;}

    if (this.step > 1) {this.step -=1 ; }
    else { window.location.href = 'https://poscalm.com/'; }

  }

  public mercadoPago(){
    this.paiyng = true;
    this.mercadoPagoService.getPayment(this.idRelatedFamily, this.code).subscribe(
      res => {
        this.init_point = res.init_point;
        window.$MPC.openCheckout({
          url: this.init_point,
          onreturn: (data) => {
            if (data.collection_status === 'approved'){

            }
          } //Acá podes obtener el evento si cancela la opercion
         });
      }
    )
  }

  public sendPaymentInfo(paymentInfo){ 
          this.userService.postUserPaymentInfo(paymentInfo)
          .subscribe( result => {
              this.step = 3
              this.router.navigate(['/sign-in', { step: 3, identify:  this.route.snapshot.params.identify}]);
              this.loadingStep = false;
        });

  }

  public getStep(result){
    this.idRelatedFamily = result.uIdRelatedFamily
    if(result.code != 'none'){
      this.price = this.formatNumber(8000)
    } else{
      this.price = this.formatNumber(10000)
    }
    if (result.location === 'sing-in'){
      this.router.navigate(['/sign-in', { step: result.step, identify:  result.uIdRelatedFamily}]);
    }
    if (result.location === 'enrollment'){
      this.router.navigate(['/enrollment/' + result.uIdRelatedFamily, {step: result.step} ]);
    }
    this.loading = false;
  }

  public formatNumber(num) {
    var c = (num.toString().indexOf ('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    return c;
  }

  public onKey(){
    this.invalidCode = false;
  }
}