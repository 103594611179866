<div style="height: 100vh;">
  <form [formGroup]="form" novalidate>
    <div *ngIf="step!=3" class="row row-desktop" style="height:87vh;">
      <div class="col-12 col-md-7 offset-md-1 mt-3 pt-4 cont-mobile">
        <div class="row pb-4 steps-mobile" data-aos="fade-down" data-aos-duration="1000">
          <div class="col-2 offset-1 col-md-2 offset-md-0 btn-go-back px-0 pb-1" style="text-align: left;"
            (click)="goBack()">
            <img class="icon mr-3 mb-1" src="./assets/logos/arrowleft1.png" stlye="margin-top: 50px;">
            <a class="a-hover">Volver</a>
          </div>
          <div class="col-9 col-md-6 offset-md-4 mt-1 pl-0">
            <div class="row">
              <div *ngIf="step===1" class="col-4 col-md-4 steps active">
                <span class="mr-1">1</span>
                <span>Registrate</span>
              </div>
              <div *ngIf="step !=1" class="col-4 col-md-4 steps check pr-0">
                <img class="poscalm-icon mr-1" src="./assets/logos/path.svg" style="width:12px;">
                <span>Registrate</span>
              </div>
              <div *ngIf="step===1" class="col-3 col-md-3 steps">
                <span class="mr-1">2</span>
                <span>Pagar</span>
              </div>
              <div *ngIf="step!=1" class="col-3 col-md-3 steps active">
                <span class="mr-1">2</span>
                <span>Pagar</span>
              </div>
              <div class="col-5 col-md-4 steps">
                <span class="mr-1">3</span>
                <span>¡Todo listo!</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-0 col-md-11">
            <div *ngIf="loadingStep">
              <div class="col-6 offset-3 col-md-4 offset-md-4 mt-5 pt-5 mb-4">
                <div class="spinner-border big-spinner" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div *ngIf="!loadingStep" class="card-enrollment">
              <div *ngIf="step===1 && !loadingStep">
                <div class="row pt-3 pl-4" style="width: 100%;">
                  <div class="col-11 col-md-6" data-aos="zoom-in">
                    <h1 class="font-dark h1-mobile" style="line-height: 1.05;">Creá tu cuenta en Poscalm</h1>
                  </div>
                  <div class="col-12 col-md-6 pt-2 div-mobile">
                    <div class="col-md-11 pb-2 none-pb" data-aos="zoom-in">
                      <button *ngIf="!authService.isLoggedIn" class="btn btn-google" (click)="authService.GoogleAuth()">
                        <img class="icon-google mb-1 mr-3" src="./assets/logos/logog.png">
                        <span class="button-google">Acceder con Google</span></button>
                      <div *ngIf="authService.isLoggedIn">
                        <div *ngIf="authService.userData as user">
                          <button *ngIf="authService.isLoggedIn" class="btn btn-google"
                            (click)="authService.GoogleAuth()">
                            <img class="icon-google mb-1 mr-3"
                              src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}"
                              style="border-radius:20px;">
                            <span class="button-google text-left" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                              {{user.displayName}}</span>
                            <div>
                              <img class="poscalm-icon" src="./assets/logos/path.svg" style="width:12px;">
                            </div>
                          </button>
                        </div>
                      </div>
                      <p *ngIf="!authService.isLoggedIn" class="text-p pl-1 pt-2">Si ya tenés tu cuenta, accede con la misma cuenta de Google que ya usaste.
                      </p>

                    </div>
                  </div>
                </div>
                <hr class="mb-2" style="width: 93%;">
                <div class="row pl-4" style="width: 100%;">
                  <div class="col-md-6 pt-5 div-2-mobile">
                    <div class="col-md-10">
                      <h1 class="font-dark" style="line-height: 1.05;" data-aos="zoom-in">Información sobre el ser
                        querido</h1>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 pt-4" data-aos="zoom-in">
                    <div class="col-12 col-md-11">
                      <h5>Nombre completo del ser querido</h5>
                      <div class="form-group mb-2"
                        [ngClass]="{ 'has-error': !form.controls.name.valid && form.controls.name.touched }">
                        <div class="input-group">
                          <input type="text" class="ml-0 name pl-2 " placeholder="Ej: Martina Lopez" name="name"
                            formControlName="name">
                        </div>
                        <!-- <span class="form-text ml-3">
                      <span *ngIf="form.controls.name.hasError('required')" style="color:red;font-size: 11px;">
                        Por favor, escribí el nombre completo del ser querido.</span>
                    </span> -->
                        <p class="text-p">Vamos a pedirte la partida de defunción más adelante.</p>
                        <p class="text-p">Debes ser familiar del fallecido y proveernos tu DNI más adelante en el
                          proceso.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
                <hr class="mb-2" style="width: 93%;">

                <div class="row pl-4 pb-2" style="width: 100%;">
                  <div class="col-md-6 pt-4 div-2-mobile">
                    <div class="col-md-10">
                      <h1 class="font-dark" style="line-height: 1.05;" data-aos="zoom-in">Código de descuento</h1>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 pt-4" data-aos="zoom-in">
                    <div class="col-12 col-md-11">
                      <h5>Ingrese el código de descuento (opcional):</h5>
                      <div class="form-group mb-2">
                        <div class="input-group">
                          <input type="text" class="ml-0 name pl-2 " placeholder="AR007722" name="code"
                            formControlName="code" (keyup)="onKey()">
                            <p *ngIf="invalidCode" class="error mb-0">El código ingresado no es correcto</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-12 p-0 w-100" style="position: fixed; bottom: 0;">
                  <div class="card" style="display:none;">
                    <div class="row" style="width: 100%;">
                      <div class="col-6" style="padding: 30px 30px 30px 40px;">
                        <div class="col-12 cost-mobile">Costo total</div>
                        <div class="col-12 price-mobile">$ {{this.price}}</div>
                      </div>
                      <div class="col-6 btn-mobile">
                        <button type="submit" *ngIf="!((step === 1 || step>1 ) && form.valid && authService.isLoggedIn)"
                          class="button-c" [disabled]="true">Continuar</button>
                        <button type="submit" *ngIf="step === 1 && form.valid && authService.isLoggedIn && !loading"
                          class="button-pay" (click)="sendData()">Continuar</button>
                        <div class="col-12 col-md-4 offset-md-4">
                          <div *ngIf="step === 1 && loading" class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="step===2">
                <div class="row pt-5 pl-4 w-100">
                  <div class="col-md-5 pl-4">
                    <span class="safe-pay"><img class="poscalm-icon mr-2" src="./assets/logos/escudo.svg"
                        style="width:24px;">Pago seguro</span>
                    <h1 class="font-dark pt-4 title-mobile" style="line-height: 1.05;">Pagá como prefieras</h1>
                  </div>
                  <div class="col-md-7 pt-4">
                    <div class="col-md-12 pl-0 pb-5">
                      <div class="row">
                        <div class="col-6 col-md-12 pr-0">
                          <h3 class="font-grey mb-4 pay-options">En hasta 18 cuotas con:</h3>
                        </div>
                        <div class="col-6 jt-mobile pl-0" style="display:none;">
                          <hr class="hr-pay">
                        </div>
                      </div>
                      <div class="row row-icons">
                        <div class="col-2 col-md-2 mt-1">
                          <img class="icon-banks-1 mb-1" src="./assets/logos/logo-visa.svg">
                        </div>
                        <div class="col-2 col-md-2 pt-2 pl-4">
                          <img class="icon-banks mb-1" src="./assets/logos/logo-master.svg">
                        </div>
                        <div class="col-2 col-md-2 pl-4">
                          <img class="icon-banks mb-1" src="./assets/logos/logo-american.svg">
                        </div>
                        <div class="col-2 col-md-2 mt-1 pl-4">
                          <img class="icon-banks-1 mb-1" src="./assets/logos/cabal.svg">
                        </div>
                        <div class="col-2 col-md-2 pl-4">
                          <img class="icon-banks  naranja-icon mb-1" src="./assets/logos/naranja3.png">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pl-4 w-100">
                  <div class="col-md-5 pt-5 btn-hide">
                    <div class="col-md-10 mb-5">
                      <button *ngIf="!paiyng" mp-mode="dftl" (click)="mercadoPago()" name="MP-payButton" class="button-pay">Pagar ahora</button> 
                      <button *ngIf="step === 2 && paiyng" class="button-c" disabled>Pagar ahora</button>

                      <!-- <button class="button-pay" (click)="mercadoPago()" >Pagar ahora</button> -->
                      <!-- <button class="button-pay" (click)="nextStep()">Pagar ahora</button> -->
                    </div>
                  </div>
                  <div class="col-md-7 pt-4 row-mobile">
                    <div class="row row-mobil-2">
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-6 col-md-12 pr-0">
                            <h3 class="font-grey title-cash">En efectivo:</h3>
                          </div>
                          <div class="col-6 jt-mobile pl-0" style="display:none;">
                            <hr class="hr-pay-2">
                          </div>
                        </div>
                        <div class="row row-icons">
                          <div class="col-4 offset-2 offset-md-0 col-md-5 mt-1">
                            <img class="icon-banks-1 icon-efectivo mb-1" src="./assets/logos/logo-rapipago.svg">
                          </div>
                          <div class="col-4 col-md-5">
                            <img class="icon-banks icon-efectivo mb-1" src="./assets/logos/pago-f-cil.svg">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5 pr-0 pl-0 pt-3">
                        <p>Para continuar con el pago, vamos a reenviarte a la página de Mercado Pago. </p>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-12 p-0 w-100">
                  <div class="card" style="display:none;">
                    <div class="row" style="width: 100%;">
                      <div class="col-6" style="padding: 30px 30px 30px 40px;">
                        <div class="col-12 cost-mobile">Costo total</div>
                        <div class="col-12 price-mobile">$ {{this.price}}</div>
                      </div>
                      <div class="col-6 btn-mobile-2 pt-4">
                        <button *ngIf="!paiyng" mp-mode="dftl" (click)="mercadoPago()" name="MP-payButton" class="button-pay">Pagar ahora</button>
                        <button *ngIf="paiyng" mp-mode="dftl" name="MP-payButton" class="button-c" disabled>Pagar
                          ahora</button>
                        <!-- <button *ngIf="!paiyng" mp-mode="dftl" (click)="nextStep()" name="MP-payButton"
                          class="button-pay">Pagar ahora</button> -->

                        <!-- <div class="col-12 col-md-4 offset-md-4">
                          <div *ngIf="step === 1 && loading" class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div> -->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row ml-3" style="margin-top:7rem;">
          <span class="footer-text">Al crear una cuenta aceptás nuestros <a class="footer-link" href="#">términos y condiciones, política de privacidad</a> y nuestra <a class="footer-link" href="#">garantía de reembolso.</a>  </span>
        </div> -->
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 right-bar">
        <div class="row" data-aos="fade-down">
          <div class="col-md-6 offset-md-1">
            <img class="poscalm-icon pt-5 " src="./assets/logos/logo.png">
          </div>
        </div>
        <div class="spacer"></div>
        <div class="row pt-5" data-aos="fade-left">
          <div class="col-md-6 offset-md-1">
            <h1>Servicio</h1>
          </div>
        </div>
        <div class="row pt-2" data-aos="fade-left">
          <div class="col-md-10 offset-md-1">
            <h3>Informe y gestión de trámites y servicios.</h3>
          </div>
        </div>
        <div class="row pt-4" data-aos="fade-left">
          <div class="col-md-10 offset-md-1">
            <div class="card">
              <h3 class="font-dark mt-3 ml-4">Informe inicial</h3>
              <h3 class="font-dark ml-4">Gestión de los trámites</h3>
              <h3 class="font-dark ml-4">Asesoramiento durante todo el proceso.</h3>
              <hr>
              <div class="row ml-2">
                <div class="col-md-6">
                  <p class="text-bold"> Costo total</p>
                </div>
                <div class="col-md-6">
                  <p class="text-bold text-center">$ {{this.price}}</p>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-7">
                <button type="submit"
                  *ngIf="!((step === 1 || step>1 ) && form.valid && authService.isLoggedIn) && step != 2 "
                  class="button-c" [disabled]="true">Continuar</button>
                <button type="submit" *ngIf="step === 1 && form.valid && authService.isLoggedIn && !loading"
                  class="button-pay" (click)="sendData()">Continuar</button>
                <div class="col-12 col-md-4 offset-md-4">
                  <div *ngIf="step === 1 && loading" class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <button *ngIf="step === 2 && !paiyng" mp-mode="dftl" (click)="mercadoPago()" name="MP-payButton"
                  class="button-pay">Pagar ahora</button>
                <button *ngIf="step === 2 && paiyng" class="button-c" (click)="nextStep()" disabled>Pagar ahora</button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row ml-3" style="margin-top:11.5rem;">
      <span class="footer-text-2"><a class="footer-link-2" href="#">Más información</a> sobre el servicio</span>
    </div> -->
      </div>
    </div>
  </form>
  <div *ngIf="step===3" class="container m-0">
    <div class="row" data-aos="fade-right" data-aos-duration="1000">
      <div class="col-md-6 offset-md-1">
        <img class="poscalm-icon pt-5 " src="./assets/logos/logo.png">
      </div>
    </div>
    <div class="col-12 col-md-4 offset-md-4">
      <div class="card card-ready pt-5 pl-4" data-aos="zoom-in-up" data-aos-duration="1000" style="position:relative!important;bottom:initial!important;">
        <div class="col-12 col-md-10">
          <img class="poscalm-icon-success mr-2 mb-4" src="./assets/logos/path.svg" style="width: 45px;">

          <h1 class="mt-2" style="color: black; padding-bottom:0.5rem; font-family: aileronlight;">¡Todo listo!</h1>
        </div>
        <div class="col-12 col-md-10">
          <p class="text-success-2">
            Recibimos correctamente tu pago. Te vamos a redireccionar de vuelta a la página para que cargues la documentación e iniciar con el informe
          </p>
        </div>
        <div class="row mt-5">
          <div class="col-5 offset-5 col-md-4 offset-md-6 text-center mb-5 btn-go-back px-0"
            style="max-width: none!important;border-bottom: 3px solid #d2d2d2;cursor:pointer;">
            <a class="a-hover-2" (click)="goToEnrollment()">Continuar</a>
            <img class="icon ml-3 mb-1" src="./assets/logos/proximo.svg" stlye="margin-top: 50px;">
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-2 *ngIf="step!=3"></app-footer-2>
</div>