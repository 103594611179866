import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { User } from "./user";
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const WAL_KEY: string = 'dkwejns'

@Injectable({
  providedIn: 'root'
})

export class UserService{
  private url = environment.apiUrl
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(
    private http: HttpClient,

  ) {
  }

  public getUsers(): Observable<any> {
    return this.http.get(`${this.url}users`, this.httpOptions)
  }

  public postUserIdRelatedFamily(data): Observable<any> {
    return this.http.post(`${this.url}relatedFamily`, data, this.httpOptions)
  }
  
  public postUserDataRelatedFamily(data): Observable<any> {
    return this.http.post(`${this.url}create/relatedFamily`, data, this.httpOptions)
  }
    
  public putUserFullData(data): Observable<any> {
    return this.http.put(`${this.url}create/relatedFamily`, data, this.httpOptions)
  }

  //Upload the name of the file with the related user id.
  public uploadFile(data): Observable<any> {
    return this.http.post(`${this.url}relatedFamily/uploadFile`, data, this.httpOptions)
  }
  
  //Save payment info
  public postUserPaymentInfo(paymentInfo) : Observable<any>{
    return this.http.post(`${this.url}paymentInfo`, paymentInfo, this.httpOptions)
  }

  public getUserLastStep(uId: any) : Observable<any>{
    return this.http.post(`${this.url}userLocationStep`, {uId: uId}, this.httpOptions)
  }
}

