<header>
  <app-nav (propagar)="procesaPropagar($event)"></app-nav>

  <div class="row row-mobile" style="margin-top: 8.5rem;width: 100%;">
  <div class="col-9 col-md-8 offset-1 offset-md-1 pl-5" data-aos-duration="1000" data-aos="fade-down" data-aos-delay="0">
    <div class="description-text animated flip">
      <span *ngIf="spanish">Realizamos trámites relacionados con la defunción de un ser querido</span>
      <span *ngIf="!spanish">We carry out procedures related to the death of a loved one</span></div>
    <div class="itemsContainer">
      <div class="items-text">- 
        <span *ngIf="spanish">Bajas de servicios y cambios de titularidad</span>
        <span *ngIf="!spanish">Termination of services and changes of ownership</span>
      </div>
      <div class="items-text">- 
        <span *ngIf="spanish"> Información de deudas</span>
        <span *ngIf="!spanish"> Debt Information</span>
      </div>
    </div> 
    <div class="row ml-2 row-header">
      <div class="col-10 col-md-3 p-0">
      <button onclick="location.href = 'https://poscalm.com/sign-in'" 
      class="contact" style="width:90%;">
      <span *ngIf="spanish">EMPEZAR AHORA</span>
      <span *ngIf="!spanish">START NOW</span>
    </button>
      </div>
      <div class="col-12 col-md-4 p-0 align-self-center text-mobile">
      <span *ngIf="spanish" class="normal-text">¿Ya tenes tu cuenta?</span>
      <span *ngIf="!spanish" class="normal-text">¿Do you have an account yet?</span>
      <a href="https://poscalm.com/sign-in" class="learn-more ml-1">
        <span *ngIf="spanish">Ingresá aquí</span>
        <span *ngIf="!spanish">Log in here</span>

      </a>
      </div>
    </div>
  </div>
</div>
  <div class="row cards-row" style="width: 100%;">
    <div class="card mr-2 ml-5">
      <div>
        <img class="icon ml-3 mt-3" src="../../assets/icons/imagen.jpg">
      </div>
      <div class="text-card">
        <div class="service-text">Ecogas</div>
        <div class="description-category">Servicios Públicos</div>
      </div>
    </div>
    
    <div class="card mr-2">
      <div>
        <img class="icon ml-3 mt-3" src="../../assets/icons/santander.png">
      </div>
      <div class="text-card">
        <div class="bank-text">Santander rio</div>
        <div class="description-category">Bancos y tarjetas</div>
      </div>
    </div>
    <div class="card mr-2">
      <div>
        <img class="icon ml-3 mt-3" src="../../assets/icons/personal.png">
      </div>
      <div class="text-card">
        <div class="provider-text">Personal</div>
        <div class="description-category">Servicios Privados</div>
      </div>
    </div>
    <div class="card mr-2">
      <div>
        <img class="icon ml-3 mt-3" src="../../assets/images/logo-muni.png">
      </div>
      <div class="text-card">
        <div class="provider-text">Municipalidad</div>
        <div class="description-category">Servicios Públicos</div>
      </div>
    </div>
    <div class="card mr-2">
      <div>
        <img class="icon ml-3 mt-3" src="../../assets/images/imagen@3x1.jpg">
      </div>
      <div class="text-card">
        <div class="provider-text">Rentas</div>
        <div class="description-category">Servicios Públicos</div>
      </div>
    </div>
  </div>
</header>