import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.getLatLon();
   }

    public getLatLon (){    
      if (navigator.geolocation)
      {
        navigator.geolocation.getCurrentPosition(function(objPosition)
        {
          var lon = objPosition.coords.longitude;
          var lat = objPosition.coords.latitude;
          console.log(lat + " " + lon)
        }, function(objPositionError)
        {

        }, {
          maximumAge: 75000,
          timeout: 15000
        });
      }
      else
      {
        console.log("Su navegador no soporta la API de geolocalización.");
      }
    }
};

