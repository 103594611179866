<footer>
    <div class="row background-img-footer no-margin" style="height: 450px;">
        <div class="col-5 col-md-5 text-right img" data-aos="fade-down" data-aos-duration="3000">
            <img class="img-desktop" src="../../assets/images/poscalm-bajada.png" style="width: 300px;">
            <img class="img-mobile" src="../../assets/images/poscalm-15.svg" style="display:none;">
        </div>
        <div *ngIf="isSpanish" class="col-7 col-md-7 align-self-center" data-aos="fade-down" data-aos-duration="1000">
            <div class="row">
                <div class="col-12 col-md-4">
                    <ul>
                        <li class="li-tittle"><span>Servicio</span></li>
                        <li class="mb-3"> <a href="#questions">Preguntas frecuentes</a></li>
                        <li class="mb-3"> <a href="https://api.whatsapp.com/send?phone=5493516156482&text=." target="_blank">Contactanos</a></li>
                        <li class="mb-3"> <a href="#legales">Consultar por trámites de sucesión</a></li>
                        <app-modal customTitle="Acerca de nosotros" modalType="4"></app-modal>
                    </ul>
                </div>
                <div class="col-12 col-md-4">
                    <ul>
                        <li class="li-tittle"><span>Usuarios registrados</span></li>
                        <li class="mb-3"><a href="https://poscalm.com/sign-in ">Ingresar a la plataforma</a></li>
                        <li><a href="https://api.whatsapp.com/send?phone=5493516156482&text=." target="_blank">Tengo un problema</a></li>
                    </ul>
                </div>
                <div class="col-12 col-md-4">
                    <ul>
                        <li class="li-tittle"><span>Accesos útiles</span></li>
                        <li class="mb-3"><a href="./assets/terminos_y_condiciones.pdf" target="_blank">Términos y condiciones</a></li>
                        <li class="mb-3"><a href="./assets/politica_de_rembolso.pdf" target="_blank">Política de reembolso</a></li>
                        <li><a href="./assets/politica_de_privacidad.pdf" target="_blank">Política de privacidad</a></li>

                    </ul>
                </div>
            </div>
        </div>
        <div *ngIf="!isSpanish" class="col-7 col-md-7 align-self-center" data-aos="fade-down" data-aos-duration="1000">
            <div class="row">
                <div class="col-12 col-md-4">
                    <ul>
                        <li class="li-tittle"><span>Service</span></li>
                        <li class="mb-3"> <a href="#questions">Frequently Asked Questions</a></li>
                        <li class="mb-3"> <a href="https://api.whatsapp.com/send?phone=5493516156482&text=." target="_blank">Contact us</a></li>
                        <li class="mb-3"> <a href="#legales">Consult for succession procedures</a></li>
                        <app-modal [spanish]="isSpanish" customTitle="About Us" modalType="4"></app-modal>
                    </ul>
                </div>
                <div class="col-12 col-md-4">
                    <ul>
                        <li class="li-tittle"><span>Registered users</span></li>
                        <li class="mb-3"><a href="https://poscalm.com/sign-in ">Login to the platform</a></li>
                        <li><a href="https://api.whatsapp.com/send?phone=5493516156482&text=." target="_blank">I have a problem</a></li>
                    </ul>
                </div>
                <div class="col-12 col-md-4">
                    <ul>
                        <li class="li-tittle"><span>Useful links</span></li>
                        <li class="mb-3"><a href="./assets/terminos_y_condiciones.pdf" target="_blank">Terms and Conditions</a></li>
                        <li class="mb-3"><a href="./assets/politica_de_rembolso.pdf" target="_blank">Refund Policy</a></li>
                        <li><a href="./assets/politica_de_privacidad.pdf" target="_blank">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom row m-0">
        <div class="col-6 col-md-7 mt-2 text-bottom-footer">
            <span>© 2020 Poscalm. All Rights Reserved</span>
        </div>
        <div class="col-4 col-md-3 mt-1 text-bottom-footer tbf" >
            <img class="logo mr-3 mb-1 flag-icon" src="../../assets/images/argentina.png">
            <span>Argentina</span>
            <a class="ml-2 mr-3" href="https://www.facebook.com/Poscalm-107214664403897" target="_blank"><i class="fab fa-facebook-square"></i></a>
            <a class="mr-3" href="https://twitter.com/PosCalm" target="_blank"><i class="fab fa-twitter-square"></i></a>
            <a class="mr-3" href="https://www.instagram.com/pos.calm/" target="_blank"><i class="fab fa-instagram"></i></a>
        </div>
    </div>
</footer>