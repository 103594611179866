<div class="row" style="height:15vh">
   <div class="col-md-7 offset-md-1  mt-5">
        <span class="footer-text">Al crear una cuenta aceptás nuestros <a class="footer-link" href="./assets/terminos_y_condiciones.pdf" 
            target="_blank">términos y condiciones,</a><a class="footer-link" href="./assets/politica_de_privacidad.pdf" target="_blank">
              política de privacidad</a>  y nuestra
            <a class="footer-link" href="./assets/politica_de_rembolso.pdf" target="_blank">garantía de reembolso.</a>  </span>
    </div>
    <div class="col-md-4 footer-left-side pl-5 pt-5">
        <span class="footer-text-2"><a class="footer-link-2" href="#">Más información</a> sobre el servicio</span>
    </div>
</div>