<form [formGroup]="form" novalidate>
    <!-- <app-nav></app-nav> -->
    <div class="div1">
        <!-- <div class="pos-f-t">
        <div class="collapse" id="navbarToggleExternalContent">
          <div class="bg-dark p-4">
            <h4 class="text-white">Collapsed content</h4>
            <span class="text-muted">Toggleable via the navbar brand.</span>
          </div>
        </div>
        <nav class="navbar navbar-dark bg-dark">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </nav>
      </div> -->
        <div class="row w-100">
            <div  class="col-2 text-mobile align-self-center pl-4 mt-2" style="display:none;" (click)="goBack()">
                <img *ngIf="step!=1" class="icon-mobile" src="./assets/logos/flecha-izquierda.png">
            </div>
            <div class="col-10 col-md-2 offset-md-10 align-self-center mt-2">
                <img class="poscalm-icon " src="./assets/logos/logo.png">
            </div>
        </div>
        <div class="row mt-5 w-100 text-desktop" data-aos="fade-down-right">
            <div class="col-12 col-md-4 mt-5">
                <div class="row ml-4 pl-3">
                    <h1>Tus trámites</h1>
                    <h3 class="mt-2">Desde acá podés gestionar tu servicio.</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="row">
            <div class="col-12 stepper pt-3"  style="display: none;">
                <app-steps [steps]="steps" [current]="stepDone-1"></app-steps>
            </div>
        </div>
        <div class="div2 row">
            <div class="col-12 col-md-3 ml-5 pt-4 div-steps" style="width:100%">
                <div class="row mb-3">
                    <div class="steps active" data-aos="zoom-in" data-aos-duration="1000">
                        <div *ngIf="stepDone===1">
                            <span class="mr-4">1</span>
                            <span *ngIf="stepDone===1 && step ===1">INFORMACIÓN DEL SER QUERIDO</span>
                        </div>
                        <div *ngIf="stepDone>1">
                            <img class="poscalm-icon mr-3" src="./assets/logos/path.svg" style="width:12px;">
                            <span class="step-done">INFORMACIÓN DEL SER QUERIDO</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="steps" data-aos="zoom-in" data-aos-duration="1200">
                        <div *ngIf="stepDone===2" class="steps active">
                            <span class="mr-4">2</span>
                            <span class="">PARTIDA DE DEFUNCIÓN</span>
                        </div>
                        <div *ngIf="stepDone<2" data-aos="zoom-in" data-aos-duration="1200">
                            <span class="mr-4">2</span>
                            <span>PARTIDA DE DEFUNCIÓN</span>
                        </div>
                        <div *ngIf="stepDone>2">
                            <img class="poscalm-icon mr-3" src="./assets/logos/path.svg" style="width:12px;">
                            <span class="step-done">PARTIDA DE DEFUNCIÓN</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="steps" data-aos="zoom-in" data-aos-duration="1400">
                        <div *ngIf="stepDone===3" class="steps active">
                            <span class="mr-4">3</span>
                            <span class="">FRENTE DNI DEL SER QUERIDO</span>
                        </div>
                        <div *ngIf="stepDone<3" data-aos="zoom-in" data-aos-duration="1400">
                            <span class="mr-4">3</span>
                            <span>FRENTE DNI DEL SER QUERIDO</span>
                        </div>
                        <div *ngIf="stepDone>3" data-aos="zoom-in" data-aos-duration="1400">
                            <img class="poscalm-icon mr-3" src="./assets/logos/path.svg" style="width:12px;">
                            <span class="step-done">FRENTE DNI DEL SER QUERIDO</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="steps" data-aos="zoom-in" data-aos-duration="1600">
                        <div *ngIf="stepDone===4" class="steps active">
                            <span class="mr-4">4</span>
                            <span class="">DORSO DNI DEL SER QUERIDO</span>
                        </div>
                        <div *ngIf="stepDone<4" data-aos="zoom-in" data-aos-duration="1600">
                            <span class="mr-4">4</span>
                            <span>DORSO DNI DEL SER QUERIDO</span>
                        </div>
                        <div *ngIf="stepDone>4" data-aos="zoom-in" data-aos-duration="1600">
                            <img class="poscalm-icon mr-3" src="./assets/logos/path.svg" style="width:12px;">
                            <span class="step-done">DORSO DNI DEL SER QUERIDO</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="steps" data-aos="zoom-in" data-aos-duration="1800">
                        <div *ngIf="stepDone===5" class="steps active">
                            <span class="mr-4">5</span>
                            <span class="">FRENTE DE TU DNI</span>
                        </div>
                        <div *ngIf="stepDone<5" data-aos="zoom-in" data-aos-duration="1800">
                            <span class="mr-4">5</span>
                            <span>FRENTE DE TU DNI</span>
                        </div>
                        <div *ngIf="stepDone>5" data-aos="zoom-in" data-aos-duration="1800">
                            <img class="poscalm-icon mr-3" src="./assets/logos/path.svg" style="width:12px;">
                            <span class="step-done">FRENTE DE TU DNI</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="steps" data-aos="zoom-in" data-aos-duration="2000">
                        <div *ngIf="stepDone===6" class="steps active">
                            <span class="mr-4">6</span>
                            <span class="">DORSO DE TU DNI</span>
                        </div>
                        <div *ngIf="stepDone<6" data-aos="zoom-in" data-aos-duration="2000">
                            <span class="mr-4">6</span>
                            <span>DORSO DE TU DNI</span>
                        </div>
                        <div *ngIf="stepDone>6" data-aos="zoom-in" data-aos-duration="2000">
                            <img class="poscalm-icon mr-3" src="./assets/logos/path.svg" style="width:12px;">
                            <span class="step-done">DORSO DE TU DNI</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div *ngIf="stepDone<7" class="steps" data-aos="zoom-in" data-aos-duration="2200">
                        <span class="mr-4">7</span>
                        <span>INFORME</span>
                    </div>
                    <div *ngIf="stepDone===7" class="steps active" data-aos="zoom-in" data-aos-duration="2200">
                        <span class="mr-4">7</span>
                        <span>INFORME</span>
                    </div>
                    <div *ngIf="stepDone>7" class="steps active" data-aos="zoom-in" data-aos-duration="2200">
                        <img class="poscalm-icon mr-3" src="./assets/logos/path.svg" style="width:12px;">
                        <span class="step-done">INFORME</span>
                    </div>
                </div>
                <div class="row mb-3">
                    <div *ngIf="stepDone<8" class="steps" data-aos="zoom-in" data-aos-duration="2400">
                        <span class="mr-4">8</span>
                        <span>GESTIÓN DE TRÁMITES</span>
                    </div>
                    <div *ngIf="stepDone===8" class="steps" data-aos="zoom-in" data-aos-duration="2400">
                        <span class="mr-4">8</span>
                        <span>GESTIÓN DE TRÁMITES</span>
                    </div>
                </div>
                <div class="spacer"></div>
                <div class="row" style="background-color: #f6f8f9;">
                    <div class="col-md-8">
                        <div class="card-help text-center py-5" data-aos="zoom-in-up">
                            <a class="help-text px-4" (click)="showOptions()">¿Necesitás ayuda?</a>
                            <div *ngIf="isShowingOptions" class="mt-3">
                                <a class="help-option" href="https://api.whatsapp.com/send?phone=5492804321096&text=."
                                    target="_blank">
                                    Contactar a mi asesor</a>
                            </div>
                            <div *ngIf="isShowingOptions" class="mt-3">
                                <a class="help-option">Preguntas frecuentes</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-7 pl-0">
                <div class="card-enrollment" data-aos="zoom-out-down" data-aos-duration="1000">
                    <div *ngIf="step===1" class="row w-100">
                        <div class="row pt-5 row-mobile-1">
                            <div class="col-11 offset-1 offset-md-1 col-md-5">
                                <h1 class="card-tittle card-mobile title-mobile">Información sobre el ser querido</h1>
                                <span class="card-text">Necesitamos pedirte algunos datos para completar tu expediente.
                                    Con
                                    estos
                                    datos vamos a poder averiguar los servicios que usaba tu ser querido. </span>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="col-11 offset-1 col-md-11 pt-1 pl-0">
                                    <span class="input-text">
                                        Nombre completo del ser querido
                                    </span>
                                    <div *ngIf="!loadingName" class="form-group"
                                        [ngClass]="{ 'has-error': !form.controls.name.valid && form.controls.name.touched }">
                                        <div class="input-group">
                                            <input type="text" class="ml-0 name pl-2 " placeholder="Ej: Martina Lopez"
                                                name="name" formControlName="name">
                                        </div>
                                    </div>
                                    <div *ngIf="loadingName" class="col-6 offset-5 col-md-4 offset-md-4 mt-2 mb-4">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-11 pt-1 pl-0">
                                    <div class="col-11 offset-1 col-md-12 pl-0 pb-3">
                                        <select id="cars" name="documentlist" form="carform" formControlName="tipoDoc"
                                            class="name documentlist pl-2">
                                            <option value="" disabled selected hidden>Tipo de documento</option>
                                            <option value="dni" style="color:red!important">DNI</option>
                                            <option value="ci">CI</option>
                                            <option value="le">LE</option>
                                            <option value="lc">LC</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-11 pl-0">
                                    <div class="col-11 offset-1 col-md-12 pl-0 pb-3">
                                        <span class="input-text">
                                            Número de DNI (sin guiones ni espacios)
                                        </span>
                                        <div class="form-group mb-0"
                                            [ngClass]="{ 'has-error': !form.controls.name.valid && form.controls.name.touched }">
                                            <input type="number" class="ml-0 name pl-3" placeholder="Ej: 12358513"
                                                formControlName="dni">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="mb-2 ml-4 mt-4 linea-mobile" style="width: 90%;">
                        <div class="row mt-5 row-mobile w-100">
                            <div class="col-11 offset-1 col-md-5 ">
                                <h1 class="card-tittle title-mobile" style="width:90%;">Información sobre vos</h1>
                            </div>
                            <div class="col-11 offset-1 col-md-6 offset-md-0 pb-3">
                                <div class="col-11 col-md-11 offset-md-1 span-mobile">
                                    <span class="input-text">
                                        Número de teléfono (whatsapp)
                                    </span>
                                </div>
                                <div class="col-12">
                                    <div class="row" style="width:100%;">
                                        <div class="col-4 col-md-3 offset-md-1 span-mobile">
                                            <div class="form-group"
                                                [ngClass]="{ 'has-error': !form.controls.caracteristica.valid && form.controls.caracteristica.touched }">
                                                <div class="input-group">
                                                    <input type="number" class="ml-0 name pl-2 " placeholder="0351"
                                                        name="name" formControlName="caracteristica">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-8 col-md-8 pl-0">
                                            <div class="form-group"
                                                [ngClass]="{ 'has-error': !form.controls.telefono.valid && form.controls.telefono.touched }">
                                                <div class="input-group">
                                                    <input type="number" class="ml-0 name pl-2 "
                                                        placeholder="4321096" name="telefono"
                                                        formControlName="telefono">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-11 offset-1 col-md-8 offset-md-3 my-4">
                                    <button class="btn button-continue" *ngIf="!form.valid"
                                        [disabled]="true">Continuar</button>
                                    <button class="btn button-continue-ok" *ngIf="form.valid && !loading"
                                        (click)="sendUserData()">Continuar</button>
                                    <div class="col-6 offset-5 col-md-4 offset-md-4">
                                        <div *ngIf="form.valid && loading" class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step===2" class="row w-100 h-100">
                        <div class="col-3 offset-1 col-md-2 offset-md-0 btn-go-back px-0 pb-1 pt-2"
                            style="text-align: center;cursor:pointer;" (click)="goBack()">
                            <img class="icon mr-2 mb-1" src="./assets/logos/arrowleft1.png" stlye="margin-top: 50px;">
                            <a class="a-hover">Volver</a>
                        </div>
                        <div class="pt-3">
                            <div class="row">
                                <div class="col-10 col-md-5 ml-5">
                                    <h1 class="card-tittle">Subí la partida de defunción</h1>
                                </div>
                                <div class="col-10 col-md-5 ml-5">
                                    <span style="font-weight: bold; color:black">Importante:</span><br>
                                    <span class="span-text">Necesitamos poder leer claramente la partida. Revisá que la
                                        foto
                                        no
                                        salga movida. Recomendamos escanear la partida para evitar problemas.</span>
                                </div>
                            </div>
                            <div class="row pt-4">
                                <div class="col-11 offset-1 col-md-10 offset-md-1">
                                    <div class="card-upload text-center px-5">
                                        <img *ngIf="!uploadDone && !previewUrl2" class="icon-google mb-1 ml-2  pt-5"
                                            src="./assets/logos/contract.svg">
                                        <br>
                                        <div class="col-12 col-md-6 offset-md-3">
                                            <span class="span-text" *ngIf="!uploadDone && !previewUrl2">Seleccioná la
                                                foto o
                                                el archivo de
                                                la partida de
                                                defunción y
                                                subila para que podamos hacer los trámites.</span>
                                        </div>
                                        <div class="container-2">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div *ngIf="fileUploadProgress">
                                                        Upload progress: {{ fileUploadProgress }}
                                                    </div>
                                                    <div class="image-preview mb-4" *ngIf="previewUrl2">
                                                        <img [src]="previewUrl2" height="320" width="520"
                                                            class="img-desktop" />
                                                        <img [src]="previewUrl2" height="100%" width="100%"
                                                            class="img-mobile" style="display:none;" />
                                                    </div>
                                                    <div class="upload-mobile" *ngIf="uploadDone">
                                                        <img class="poscalm-icon mr-3" src="./assets/logos/path.svg"
                                                            style="width:12px;">
                                                        <span class="upload-success">Se subió correctamente.</span>
                                                    </div>

                                                    <div class="mb-3" *ngIf="uploadedFilePath">
                                                        {{uploadedFilePath}}
                                                    </div>
                                                    <div *ngIf="!previewUrl2" class="form-group pb-5 form-mobile">
                                                        <div class="col-12 col-md-6 offset-md-3">
                                                            <input *ngIf="!fileUploadProgress" type="file" name="image"
                                                                id="image" class="inputfile"
                                                                (change)="cambioArchivo($event)" />
                                                            <label *ngIf="!uploadDone && !previewUrl2" for="image"
                                                                class="btn button-pay mt-5">CARGAR AQUI</label>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="previewUrl2" class="form-group pb-1">
                                                        <div class="col-12 col-md-6 offset-md-3">
                                                            <input *ngIf="!fileUploadProgress" type="file" name="image"
                                                                id="image" class="inputfile"
                                                                (change)="cambioArchivo($event)" />
                                                            <label *ngIf="!uploadDone && !previewUrl2" for="image"
                                                                class="btn button-pay mt-5">CARGAR AQUI</label>
                                                            <input *ngIf="previewUrl2" type="file" name="image2"
                                                                id="image2" class="inputfile" (change)="changeFile()" />
                                                            <!-- <label *ngIf="previewUrl && !uploadDone" for="image"
                                                            class="btn button-change-file"
                                                            style="background-color: grey; border:none;">ELEGIR OTRO
                                                            ARCHIVO</label> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!loading" class="col-12 col-md-6 offset-md-6 pr-0">
                                        <div class="row">
                                            <div class="col-6 col-md-6 align-self-center">
                                                <label *ngIf="previewUrl2 && !uploadDone" for="image"
                                                    class="mb-0 change-file">
                                                    Elegir otro archivo
                                                </label>
                                            </div>
                                            <div class="col-6 col-md-6 align-self-center pl-0">
                                                <div class="form-group text-center mt-3">
                                                    <button class="btn button-change-file" [disabled]="!previewUrl2"
                                                        *ngIf="!uploadDone" (click)="subirArchivo()">Subir
                                                        archivo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!loading" class="col-12 col-md-6 offset-md-6 pl-0">
                                        <div class="row">
                                            <div class="col-6 col-md-6 align-self-center">
                                                <label *ngIf="previewUrl2 && uploadDone" for="image"
                                                    class="mb-0 change-file">
                                                    Elegir otro archivo
                                                </label>
                                            </div>
                                            <div class="col-6 col-md-6 pr-0">
                                                <div class="form-group text-center mt-1">
                                                    <button class="btn button-change-file" *ngIf="uploadDone"
                                                        (click)="nextStepUpload()">Continuar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 offset-5 col-md-1 offset-md-10 mb-5 mt-3">
                                        <div *ngIf="loading" class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step===3" class="row w-100">
                        <div class="col-3 offset-1 col-md-2 offset-md-0 btn-go-back px-0 pb-1 pt-2"
                            style="text-align: center;cursor:pointer;" (click)="goBack()">
                            <img class="icon mr-2 mb-1" src="./assets/logos/arrowleft1.png" stlye="margin-top: 50px;">
                            <a class="a-hover">Volver</a>
                        </div>
                        <div class="pt-3">
                            <div class="row">
                                <div class="col-10 col-md-5 ml-5">
                                    <h1 class="card-tittle">Subí el frente del documento del ser querido (Opcional)</h1>
                                </div>
                                <div class="col-10 col-md-5 ml-5">
                                    <span style="font-weight: bold; color:black">Importante:</span><br>
                                    <span class="span-text">Tener la foto del DNI del ser querido va a agilizar la
                                        gestión de los trámites.
                                        En caso de no tenerlo, no te preocupes y continúa con el siguiente paso.
                                    </span>
                                </div>
                            </div>
                            <div class="row pt-1">
                                <div class="col-11 offset-1 col-md-10 offset-md-1">
                                    <div class="card-upload text-center px-5">
                                        <img *ngIf="!previewUrl3 && !dniFrontDead" class="icon-google mb-1 ml-2  pt-5"
                                            src="./assets/logos/contract.svg">
                                        <br>
                                        <div class="col-12 col-md-6 offset-md-3">
                                            <span class="span-text" *ngIf="!dniFrontDead && !previewUrl3">Seleccioná la
                                                foto
                                                o el archivo de
                                                la partida del frente del DNI y
                                                subila para que podamos hacer los trámites.</span>
                                        </div>
                                        <div class="container-2">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div *ngIf="fileUploadProgress">
                                                        Upload progress: {{ fileUploadProgress }}
                                                    </div>
                                                    <div class="image-preview mb-4" *ngIf="previewUrl3">
                                                        <img [src]="previewUrl3" height="320" width="520"
                                                            class="img-desktop" />
                                                        <img [src]="previewUrl3" height="100%" width="100%"
                                                            class="img-mobile" style="display:none;" />
                                                    </div>
                                                    <div class="upload-mobile" *ngIf="dniFrontDead">
                                                        <img class="poscalm-icon mr-3" src="./assets/logos/path.svg"
                                                            style="width:12px;">
                                                        <span class="upload-success">Se subió correctamente.</span>
                                                    </div>
                                                    <div class="mb-3" *ngIf="uploadedFilePath">
                                                        {{uploadedFilePath}}
                                                    </div>
                                                    <div *ngIf="!previewUrl3" class="form-group pb-5 form-mobile">
                                                        <div class="col-12 col-md-6 offset-md-3">
                                                            <input *ngIf="!fileUploadProgress" type="file" name="image"
                                                                id="image" class="inputfile"
                                                                (change)="cambioArchivo($event)" />
                                                            <label *ngIf="!previewUrl3 && !dniFrontDead" for="image"
                                                                class="btn button-pay mt-5">CARGAR AQUI</label>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="previewUrl3" class="form-group pb-1">
                                                        <div class="col-12 col-md-6 offset-md-3">
                                                            <input *ngIf="!fileUploadProgress" type="file" name="image"
                                                                id="image" class="inputfile"
                                                                (change)="cambioArchivo($event)" />
                                                            <label *ngIf="!previewUrl3 && !dniFrontDead" for="image"
                                                                class="btn button-pay mt-5">CARGAR AQUI</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!loading && previewUrl3 && !dniFrontDead"
                                        class="col-12 col-md-6 offset-md-6 pr-0">
                                        <div class="row pt-2">
                                            <div class="col-6 col-md-6 align-self-center">
                                                <label *ngIf="previewUrl3 && !dniFrontDead" for="image"
                                                    class="mb-3 change-file">
                                                    Elegir otro archivo
                                                </label>
                                            </div>
                                            <div class="col-6 col-md-6 align-self-center pl-0">
                                                <div class="form-group text-center">
                                                    <button class="btn button-change-file"
                                                        *ngIf="previewUrl3 && !dniFrontDead"
                                                        (click)="subirArchivo()">Subir Archivo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!loading" class="col-12 col-md-6 offset-md-6 pl-0">
                                        <div class="row pt-2">
                                            <div class="col-6 col-md-6 align-self-center mb-3">
                                                <label *ngIf="previewUrl3 && dniFrontDead" for="image"
                                                    class="mb-0 change-file">
                                                    Elegir otro archivo
                                                </label>
                                            </div>
                                            <div class="col-6 col-md-6 pr-0">
                                                <div class="form-group text-center">
                                                    <button class="btn button-change-file"
                                                        *ngIf="(dniFrontDead || !previewUrl3) && !uploadAnotherFile"
                                                        (click)="nextStepUpload()">Continuar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 offset-5 col-md-1 offset-md-10 mb-5 mt-3">
                                        <div *ngIf="loading" class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step===4" class="row w-100">
                        <div class="col-3 offset-1 col-md-2 offset-md-0 btn-go-back px-0 pb-1 pt-2"
                            style="text-align: center;cursor:pointer;" (click)="goBack()">
                            <img class="icon mr-2 mb-1" src="./assets/logos/arrowleft1.png" stlye="margin-top: 50px;">
                            <a class="a-hover">Volver</a>
                        </div>
                        <div class="pt-3">
                            <div class="row">
                                <div class="col-10 col-md-5 ml-5">
                                    <h1 class="card-tittle">Subí el dorso del documento del ser querido (Opcional)</h1>
                                </div>
                                <div class="col-10 col-md-5 ml-5">
                                    <span style="font-weight: bold; color:black">Importante:</span><br>
                                    <span class="span-text">Tener la foto del DNI del ser querido va a agilizar la
                                        gestión de los trámites.
                                        En caso de no tenerlo, no te preocupes y continúa con el siguiente paso.

                                    </span>
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-11 offset-1 col-md-10 offset-md-1">
                                    <div class="card-upload text-center px-5">
                                        <img *ngIf="!previewUrl4 && !dniBackDead" class="icon-google mb-1 ml-2  pt-5"
                                            src="./assets/logos/contract.svg">
                                        <br>
                                        <div class="col-12 col-md-6 offset-md-3">
                                            <span class="span-text" *ngIf="!previewUrl4 && !dniBackDead">Seleccioná la
                                                foto
                                                o el archivo del
                                                dorso del DNI y
                                                subila para que podamos hacer los trámites.</span>
                                        </div>
                                        <div class="container-2">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div *ngIf="fileUploadProgress">
                                                        Upload progress: {{ fileUploadProgress }}
                                                    </div>
                                                    <div class="image-preview mb-4" *ngIf="previewUrl4">
                                                        <img [src]="previewUrl4" height="320" width="520"
                                                            class="img-desktop" />
                                                        <img [src]="previewUrl4" height="100%" width="100%"
                                                            class="img-mobile" style="display:none;" />
                                                    </div>
                                                    <div class="upload-mobile" *ngIf="dniBackDead">
                                                        <img class="poscalm-icon mr-3" src="./assets/logos/path.svg"
                                                            style="width:12px;">
                                                        <span class="upload-success">Se subió correctamente.</span>
                                                    </div>
                                                    <div class="mb-3" *ngIf="uploadedFilePath">
                                                        {{uploadedFilePath}}
                                                    </div>
                                                    <div *ngIf="!previewUrl4" class="form-group pb-5 form-mobile">
                                                        <div class="col-12 col-md-6 offset-md-3">
                                                            <input *ngIf="!fileUploadProgress" type="file" name="image"
                                                                id="image" class="inputfile"
                                                                (change)="cambioArchivo($event)" />
                                                            <label *ngIf="!previewUrl4 && !dniBackDead" for="image"
                                                                class="btn button-pay mt-5">CARGAR AQUI</label>
                                                            <!-- <input *ngIf="previewUrl4" type="file" name="image2" id="image2"
                                                            class="inputfile" (change)="changeFile()" />
                                                        <label *ngIf="previewUrl4" for="image"
                                                            class="btn button-change-file">Elegir otro archivo</label> -->
                                                        </div>
                                                    </div>
                                                    <div *ngIf="previewUrl4" class="form-group pb-1">
                                                        <div class="col-12 col-md-6 offset-md-3">
                                                            <input *ngIf="!fileUploadProgress" type="file" name="image"
                                                                id="image" class="inputfile"
                                                                (change)="cambioArchivo($event)" />
                                                            <label *ngIf="!previewUrl4 && !dniBackDead" for="image"
                                                                class="btn button-pay mt-5">CARGAR AQUI</label>
                                                            <!-- <input *ngIf="previewUrl4" type="file" name="image2" id="image2"
                                                            class="inputfile" (change)="changeFile()" />
                                                        <label *ngIf="previewUrl4 && !dniBackDead" for="image"
                                                            class="btn button-change-file"
                                                            style="background-color: grey; border:none;">ELEGIR OTRO
                                                            ARCHIVO</label> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!loading && previewUrl4 && !dniBackDead"
                                        class="col-12 col-md-6 offset-md-6 pr-0">
                                        <div class="row pt-2">
                                            <div class="col-6 col-md-6 align-self-center">
                                                <label *ngIf="previewUrl4 && !dniBackDead" for="image"
                                                    class="mb-3 change-file">
                                                    Elegir otro archivo
                                                </label>
                                            </div>
                                            <div class="col-6 col-md-6 align-self-center pl-0">
                                                <div class="form-group text-center">
                                                    <button class="btn button-change-file"
                                                        *ngIf="previewUrl4 && !dniBackDead"
                                                        (click)="subirArchivo()">Subir Archivo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!loading" class="col-12 col-md-6 offset-md-6 pl-0">
                                        <div class="row pt-2">
                                            <div class="col-6 col-md-6 align-self-center mb-3">
                                                <label *ngIf="previewUrl4 && dniBackDead" for="image"
                                                    class="mb-0 change-file">
                                                    Elegir otro archivo
                                                </label>
                                            </div>
                                            <div class="col-6 col-md-6 pr-0">
                                                <div class="form-group text-center">
                                                    <button class="btn button-change-file"
                                                        *ngIf="(dniBackDead || !previewUrl4) && !uploadAnotherFile"
                                                        (click)="nextStepUpload()">Continuar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 offset-5 col-md-1 offset-md-10 mb-5 pt-2">
                                        <div *ngIf="loading" class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step===5" class="row w-100">
                        <div class="col-3 offset-1 col-md-2 offset-md-0 btn-go-back px-0 pb-1 pt-2"
                            style="text-align: center;cursor:pointer;" (click)="goBack()">
                            <img class="icon mr-2 mb-1" src="./assets/logos/arrowleft1.png" stlye="margin-top: 50px;">
                            <a class="a-hover">Volver</a>
                        </div>
                        <div class="pt-3">
                            <div class="row w-100">
                                <div class="col-10 col-md-5 ml-5">
                                    <h1 class="card-tittle">Subí el frente de tu documento</h1>
                                </div>
                                <div class="col-10 col-md-5 ml-5">
                                    <span style="font-weight: bold; color:black">Importante:</span><br>
                                    <span class="span-text">Subí la foto de frente de tu DNI.
                                    </span>
                                </div>
                            </div>
                            <div class="row pt-4 w-100">
                                <div class="col-12 offset-1 col-md-10 offset-md-1">
                                    <div class="card-upload text-center px-5">
                                        <img *ngIf="!dniFrontUser && !previewUrl5" class="icon-google mb-1 ml-2  pt-5"
                                            src="./assets/logos/contract.svg">
                                        <br>
                                        <div class="col-12 col-md-6 offset-md-3">
                                            <span class="span-text" *ngIf="!dniFrontUser && !previewUrl5">Seleccioná la
                                                foto
                                                o el archivo del
                                                frente del DNI y
                                                subila para que podamos hacer los trámites.</span>
                                        </div>
                                        <div class="container-2">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div *ngIf="fileUploadProgress">
                                                        Upload progress: {{ fileUploadProgress }}
                                                    </div>
                                                    <div class="image-preview mb-4" *ngIf="previewUrl5">
                                                        <img [src]="previewUrl5" height="320" width="520"
                                                            class="img-desktop" />
                                                        <img [src]="previewUrl5" height="100%" width="100%"
                                                            class="img-mobile" style="display:none;" />
                                                    </div>
                                                    <div class="upload-mobile" *ngIf="dniFrontUser">
                                                        <img class="poscalm-icon mr-3" src="./assets/logos/path.svg"
                                                            style="width:12px;">
                                                        <span class="upload-success">Se subió correctamente.</span>
                                                    </div>
                                                    <div class="mb-3" *ngIf="uploadedFilePath">
                                                        {{uploadedFilePath}}
                                                    </div>
                                                    <div *ngIf="!previewUrl5" class="form-group pb-5 form-mobile">
                                                        <div class="col-12 col-md-6 offset-md-3">
                                                            <input *ngIf="!fileUploadProgress" type="file" name="image"
                                                                id="image" class="inputfile"
                                                                (change)="cambioArchivo($event)" />
                                                            <label *ngIf="!previewUrl5 && !dniFrontUser" for="image"
                                                                class="btn button-pay mt-5">CARGAR AQUI</label>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="previewUrl5" class="form-group pb-1">
                                                        <div class="col-12 col-md-6 offset-md-3">
                                                            <input *ngIf="!fileUploadProgress" type="file" name="image"
                                                                id="image" class="inputfile"
                                                                (change)="cambioArchivo($event)" />
                                                            <label *ngIf="!previewUrl5 && !dniFrontUser" for="image"
                                                                class="btn button-pay mt-5">CARGAR AQUI</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!loading && !dniFrontUser" class="col-12 col-md-6 offset-md-6 pr-0">
                                        <div class="row pt-3">
                                            <div class="col-6 col-md-6 align-self-center">
                                                <label *ngIf="previewUrl5 && !dniFrontUser" for="image"
                                                    class="mb-0 change-file">
                                                    Elegir otro archivo
                                                </label>
                                            </div>
                                            <div class="col-6 col-md-6 align-self-center pl-0">
                                                <div class="form-group text-center mt-1">
                                                    <button class="btn button-change-file" [disabled]="!previewUrl5"
                                                        *ngIf="!dniFrontUser" (click)="subirArchivo()">Subir
                                                        archivo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!loading" class="col-12 col-md-6 offset-md-6 pl-0">
                                        <div class="row pt-3">
                                            <div class="col-6 col-md-6 align-self-center">
                                                <label *ngIf="previewUrl5 && dniFrontUser" for="image"
                                                    class="mb-0 change-file">
                                                    Elegir otro archivo
                                                </label>
                                            </div>
                                            <div class="col-6 col-md-6 pr-0">
                                                <div class="form-group text-center mt-1">
                                                    <button class="btn button-change-file" *ngIf="dniFrontUser"
                                                        (click)="nextStepUpload()">Continuar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 offset-5 col-md-1 offset-md-10 mb-5 pt-3">
                                        <div *ngIf="loading" class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step===6" class="row w-100">
                        <div class="col-3 offset-1 col-md-2 offset-md-0 btn-go-back px-0 pb-1 pt-2"
                            style="text-align: center;cursor:pointer;" (click)="goBack()">
                            <img class="icon mr-2 mb-1" src="./assets/logos/arrowleft1.png" stlye="margin-top: 50px;">
                            <a class="a-hover">Volver</a>
                        </div>
                        <div class="pt-3">
                            <div class="row w-100">
                                <div class="col-10 col-md-5 ml-5">
                                    <h1 class="card-tittle">Subí el dorso de tu documento</h1>
                                </div>
                                <div class="col-10 col-md-5 ml-5">
                                    <span style="font-weight: bold; color:black">Importante:</span><br>
                                    <span class="span-text">Subí la foto del dorso de tu DNI.
                                    </span>
                                </div>
                            </div>
                            <div class="row pt-4 w-100">
                                <div class="col-12 offset-1 col-md-10 offset-md-1">
                                    <div class="card-upload text-center px-5">
                                        <img *ngIf="!previewUrl6 && !dniBackUser" class="icon-google mb-1 ml-2  pt-5"
                                            src="./assets/logos/contract.svg">
                                        <br>
                                        <div class="col-12 col-md-6 offset-md-3">
                                            <span class="span-text" *ngIf="!previewUrl6 && !dniBackUser">Seleccioná la
                                                foto
                                                o el archivo del
                                                dorso del DNI y
                                                subila para que podamos hacer los trámites.</span>
                                        </div>
                                        <div class="container-2">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div *ngIf="fileUploadProgress">
                                                        Upload progress: {{ fileUploadProgress }}
                                                    </div>
                                                    <div class="image-preview mb-4" *ngIf="previewUrl6">
                                                        <img [src]="previewUrl6" height="320" width="520"
                                                            class="img-desktop" />
                                                        <img [src]="previewUrl6" height="100%" width="100%"
                                                            class="img-mobile" style="display:none;" /> </div>
                                                    <div class="upload-mobile" *ngIf="dniBackUser">
                                                        <img class="poscalm-icon mr-3" src="./assets/logos/path.svg"
                                                            style="width:12px;">
                                                        <span class="upload-success">Se subió correctamente.</span>
                                                    </div>
                                                    <div class="mb-3" *ngIf="uploadedFilePath">
                                                        {{uploadedFilePath}}
                                                    </div>
                                                    <div *ngIf="!previewUrl6" class="form-group pb-5 form-mobile">
                                                        <div class="col-12 col-md-6 offset-md-3">
                                                            <input *ngIf="!fileUploadProgress" type="file" name="image"
                                                                id="image" class="inputfile"
                                                                (change)="cambioArchivo($event)" />
                                                            <label *ngIf="!previewUrl6 && !dniBackUser" for="image"
                                                                class="btn button-pay mt-5">CARGAR AQUI</label>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="previewUrl6" class="form-group pb-1">
                                                        <div class="col-12 col-md-6 offset-md-3">
                                                            <input *ngIf="!fileUploadProgress" type="file" name="image"
                                                                id="image" class="inputfile"
                                                                (change)="cambioArchivo($event)" />
                                                            <label *ngIf="!previewUrl6 !dniBackUser" for="image"
                                                                class="btn button-pay mt-5">CARGAR AQUI</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!loading && !dniBackUser" class="col-12 col-md-6 offset-md-6 pr-0">
                                        <div class="row pt-3">
                                            <div class="col-6 col-md-6 align-self-center">
                                                <label *ngIf="previewUrl6 && !dniBackUser" for="image"
                                                    class="mb-0 change-file">
                                                    Elegir otro archivo
                                                </label>
                                            </div>
                                            <div class="col-6 col-md-6 align-self-center pl-0">
                                                <div class="form-group text-center mt-1">
                                                    <button class="btn button-change-file" [disabled]="!previewUrl6"
                                                        *ngIf="!dniBackUser" (click)="subirArchivo()">Subir
                                                        archivo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!loading && dniBackUser" class="col-12 col-md-6 offset-md-6 pl-0">
                                        <div class="row pt-3">
                                            <div class="col-6 col-md-6 align-self-center">
                                                <label *ngIf="previewUrl6 && dniBackUser" for="image"
                                                    class="mb-0 change-file">
                                                    Elegir otro archivo
                                                </label>
                                            </div>
                                            <div class="col-6 col-md-6 pr-0">
                                                <div class="form-group text-center mt-1">
                                                    <button class="btn button-change-file" *ngIf="dniBackUser"
                                                        (click)="nextStepUpload()">Continuar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="loading" class="col-6 offset-5 col-md-1 offset-md-10 mb-5 pt-3">
                                        <div *ngIf="loading" class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step===7" class="row w-100">
                        <!-- <div class="col-3 offset-1 col-md-2 offset-md-0 btn-go-back px-0 pb-1 pt-2"
                            style="text-align: center;cursor:pointer;" (click)="goBack()">
                            <img class="icon mr-2 mb-1" src="./assets/logos/arrowleft1.png" stlye="margin-top: 50px;">
                            <a class="a-hover">Volver</a>
                        </div> -->
                        <div class="pt-3">
                            <div class="row w-100">
                                <div class="col-10 col-md-5 ml-5">
                                    <h1 class="card-tittle">Estamos preparando tu informe</h1>
                                </div>
                                <div class="col-10 col-md-5 ml-5">
                                    <span class="span-text">Estamos preparando tu informe con todos los servicios y
                                        empresas
                                        a los que {{nameRelatedFamily}} estaba suscripto/a.</span>
                                        <br><br><span style="color: #000; font-family:aileronbold;">A la brevedad te vamos a contactar por teléfono para darte visibilidad del avance y evacuar dudas.
                                    </span>
                                    <div class="row mt-5 ml-3">
                                        <div class="row" style="width: 350px;">
                                            <div class="col-3 col-md-4 green-card pt-4 pb-3">
                                                <p>En curso</p>
                                            </div>
                                            <div class="col-7 col-md-8 grey-card">
                                                <p class="pt-3 mb-0">Fecha estimada de entrega</p>
                                                <span class="span-card">{{currentDate()}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <hr class="mb-2 ml-4 mt-5" style="width: 90%;">
                        <div class="row mt-2 pt-5 pb-5" style="width: 100%;">
                            <div class="col-10 offset-2 offset-md-1 col-md-5  mr-3">
                                <h1 class="card-tittle" style="width:90%;">Preguntas frecuentes</h1>
                            </div>
                            <div class="col-md-5 pb-3 qidiv">
                                <div class="questions-items pl-4">
                                    <div class="row pl-2 pb-2"><a (click)="showQuestion(1)">¿Qué es el informe?</a>
                                    </div>
                                    <p class="question-text" *ngIf="wichQuestion == 1 && isShowing">
                                        Precisás presentar el acta de defunción del ser
                                        querido. Este papel es indispensable para empezar el informe. Dependiendo del
                                        resultado puede que
                                        solicitemos documentación adicional, pero no suele ser necesario.</p>
                                    <div class="row pl-2 pb-2"><a (click)="showQuestion(2)">¿Cuánto suele tardar el
                                            informe?</a></div>
                                    <p class="question-text" *ngIf="wichQuestion == 2 && isShowing">El
                                        informe tarda aproximadamente entre 10 y 14 dias.</p>
                                    <div class="row pl-2 pb-2"><a (click)="showQuestion(3)">¿Qué empresas incluye?</a>
                                    </div>
                                    <p class="question-text" *ngIf="wichQuestion == 3 && isShowing">
                                        Precisás presentar el acta de defunción de ser
                                        querido. Este papel es indispensable para empezar el informe. Dependiendo del
                                        resultado puede que
                                        solicitemos documentación adicional, pero no suele ser necesario.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>