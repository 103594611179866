import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.styl']
})
export class MainComponent implements OnInit {
  @Input() spanish: boolean;

  public wichQuestion;
  public isShowing = false;
  public isSpanish: boolean;

  constructor() {  }
  ngOnInit(): void {
    this.isSpanish = this.spanish;

  }


  showQuestion( ques ){
    if(this.wichQuestion == ques)
    {
      this.isShowing = false;
      this.wichQuestion = 0;
    }
    else{
      this.isShowing = true;
      this.wichQuestion = ques;
    }
  }
}
