<nav class="navbar navbar-expand-lg navbar-light col-12 col-md-11 offset-md-1 col-xs-12" data-aos-duration="600"
  data-aos="fade-down" data-aos-delay="0">
  <button id="button-toggler" class="navbar-toggler mt-3" type="button" data-toggle="collapse"
    data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
    aria-label="Toggle navigation" (click)="openNav()">
    <span class="navbar-toggler-icon" style="border-color: rgb(255,102,203)">
      <i class="fas fa-bars" style="color:#fff; font-size:28px;"></i>
    </span>
  </button>
  <img class="poscalm-icon pt-3 pl-4 ml-2" src="../../assets/images/logo.png">
  <a id="closebtn" href="#" class="closebtn" (click)="closeNav()"
    style="display:none; color: white!important; font-size:40px!important;">&times;</a>

  <!-- <div *ngIf="isCollapsed" class="col-8 ml-5 nav-mobile">
    <div class="pt-3 mt-1" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a class="nav-item nav-link mr-3" href="#service">
          <span *ngIf="isSpanish">Servicio</span>
          <span *ngIf="!isSpanish">Service</span>  
          <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link mr-3" href="#price">
          <span *ngIf="isSpanish">Precio</span>
          <span *ngIf="!isSpanish">Price</span>
        </a>
        <a class="nav-item nav-link mr-3" href="#questions">
          <span *ngIf="isSpanish">Preguntas</span>
          <span *ngIf="!isSpanish">Questions</span>

        </a>
        <a class="nav-item nav-link mr-3" onclick="window.open('https://medium.com/@magalimazzieri','_blank')">Blog</a>
        <a *ngIf="isSpanish" class="nav-item nav-link mr-3" (click)="changeLanguage()">Language</a>
        <a *ngIf="!isSpanish" class="nav-item nav-link mr-3" (click)="changeLanguage()">Idioma</a>
      </div>
    </div>
  </div> -->
  <div class="col-md-6">
    <div class="pt-3 mt-1 nav-desktop">
      <div class="navbar-nav ml-5" style="position:initial;">
        <a class="nav-item nav-link mr-3" href="#service">
          <span *ngIf="isSpanish">Servicio</span>
          <span *ngIf="!isSpanish">Service</span>
          <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link mr-3" href="#price">
          <span *ngIf="isSpanish">Precio</span>
          <span *ngIf="!isSpanish">Price</span>
        </a>
        <a class="nav-item nav-link mr-3" href="#questions">
          <span *ngIf="isSpanish">Preguntas</span>
          <span *ngIf="!isSpanish">Questions</span>
        </a>
        <a class="nav-item nav-link mr-3" onclick="window.open('https://medium.com/@magalimazzieri','_blank')">Blog</a>
        <a class="nav-item nav-link mr-3" (click)="openLg(content);openModal()" style="cursor:pointer;">Video</a>
        <a *ngIf="isSpanish" class="nav-item nav-link mr-3" (click)="changeLanguage()" style="cursor:pointer;">Language</a>
        <a *ngIf="!isSpanish" class="nav-item nav-link mr-3" (click)="changeLanguage()" style="cursor:pointer;">Idioma</a>
      </div>
    </div>
  </div>
</nav>

<div id="mySidenav" class="sidenav" style="display:none;">
  <div class="col-11 offset-1 pl-0">
    <a class="nav-item nav-link mr-3" href="#service" (click)="closeNav()">
      <span *ngIf="isSpanish">Servicio</span>
      <span *ngIf="!isSpanish">Service</span>
      <span class="sr-only">(current)</span></a>
    <a class="nav-item nav-link mr-3" href="#price" (click)="closeNav()">
      <span *ngIf="isSpanish">Precio</span>
      <span *ngIf="!isSpanish">Price</span>
    </a>
    <a class="nav-item nav-link mr-3" href="#questions" (click)="closeNav()">
      <span *ngIf="isSpanish">Preguntas</span>
      <span *ngIf="!isSpanish">Questions</span>
    </a>
    <a class="nav-item nav-link mr-3 " onclick="window.open('https://medium.com/@magalimazzieri','_blank')"
      (click)="closeNav()">Blog</a>
    <a class="nav-item nav-link mr-3" (click)="openLg(content);openModal()" style="cursor:pointer;">Video</a>

    <a *ngIf="isSpanish" class="nav-item nav-link mr-3 mb-4" (click)="changeLanguage()" style="cursor:pointer;">Language</a>
    <a *ngIf="!isSpanish" class="nav-item nav-link mr-3 mb-4" (click)="changeLanguage()" style="cursor:pointer;">Idioma</a>
  </div>
  <!-- <div class="row">
    <a class="ml-5 pl-4" href="https://www.facebook.com/Poscalm-107214664403897" target="_blank"><i class="fab fa-facebook-square"></i></a>
    <a  href="https://twitter.com/AgusLopez36" target="_blank"><i class="fab fa-twitter-square"></i></a>
    <a  href="https://www.instagram.com/pos.calm/" target="_blank"><i class="fab fa-instagram"></i></a>
  </div>

  <div class="col-8 offset-2 mt-2 text-bottom-footer">
    <span>© 2020 Poscalm. All Rights Reserved</span>
  </div> -->
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title"><img class="logo mr-3 mb-1 flag-icon" src="../../assets/images/poscalm-19.png" style="width:30%">
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="embed-responsive embed-responsive-16by9">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/LVI2ORkQICQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  </div>
    </div>
      <div class="modal-footer">
    <button type="button" class="btn btn-close" (click)="modal.close('Close click');closeModal()">Cerrar</button>
  </div>
</ng-template>