import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl']
})
export class HeaderComponent implements OnInit {
  @Input() spanish: boolean;
  @Output() propagar = new EventEmitter<boolean>();

  public isSpanish;
  constructor() { }

  ngOnInit(): void {
    this.isSpanish = this.spanish;
  }

  public procesaPropagar(event){
    console.log(event)
    this.isSpanish = event
    this.propagar.emit(this.spanish);
  }
}
