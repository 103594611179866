import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.styl']
})
export class ModalComponent implements OnInit {
  public title : any
  public type : number
  @Input() customTitle: string;
  @Input() modalType: number;
  @Input() spanish: boolean
  public itemsSelected : any
  public isSpanish: boolean
  constructor(public  modal: NgbModal, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

  ngOnInit(): void {
    this.isSpanish = this.spanish
    console.log("footer:" + this.spanish)
    this.title = this.customTitle
    this.type = this.modalType
    console.log(this.type)
    if(this.type==1){this.intializeBanks()};
    if(this.type==2) {this.intializePublicServices()};
    if(this.type==3) {this.intializePrivateServices()};
  }

  public intializeBanks(){
    this.itemsSelected = ['The Royal Bank of Scotland N.V.','Banco de Galicia y Buenos Aires Sociedad Anónima','Banco de la Nación Argentina','Banco de la Provincia de Buenos Aires',
                        'Industrial and Commercial Bank of China (ARGENTINA) S.A.','Citibank N.A.','BBVA Banco Francés S.A.','The Bank of Tokyo-Mitsubishi UFJ, Ltd.','Banco de la Provincia de Córdoba S.A.',
                        'Banco Supervielle S.A.','Banco de la Ciudad de Buenos Aires','Banco Patagonia S.A.','Banco Hipotecario S.A.','Banco de San Juan S.A.','Banco do Brasil S.A.',
                        'Banco del Tucumán S.A.','Banco Municipal de Rosario','Banco Santander Río S.A.','Banco del Chubut S.A.','Banco de Santa Cruz S.A.',
                        'Banco de La Pampa Sociedad de Economía Mixta','Banco de Corrientes S.A.','Banco Provincia del Neuquén Sociedad Anónima','Banco Interfinanzas S.A.',
                        'HSBC Bank Argentina S.A.','JPMorgan Chase Bank, National Association (Sucursal Buenos Aires)','Banco Credicoop Cooperativo Limitado','Banco de Valores S.A.',
                        'Banco Roela S.A.','Banco Mariva S.A.','Banco Itaú Argentina S.A.','Bank of America, National Association','BNP Paribas','Banco Provincia de Tierra del Fuego',
                        'Banco de la República Oriental del Uruguay','Banco Sáenz S.A.','Banco Meridian S.A.','Banco Macro S.A.','American Express Bank Ltd. Sociedad Anónima',
                        'Banco Comafi Sociedad Anónima','Banco de Inversión y Comercio Exterior S.A.','Banco Piano S.A.','Banco Finansur S.A.','Banco Julio Sociedad Anónima',
                        'Banco Privado de Inversiones Sociedad Anónima','Banco Rioja Sociedad Anonima Unipersonal','Banco del Sol S.A.',
                        'Nuevo Banco del Chaco S.A.','Banco Voii S.A.','Banco de Formosa S.A.','Banco CMF S.A.','Banco de Santiago del Estero S.A.',
                        'Banco Industrial S.A.','Deutsche Bank S.A.','Nuevo Banco de Santa Fe S.A.','Banco Cetelem Argentina S.A.','Banco de Servicios Financieros S.A.',
                        'Banco Bradesco Argentina S.A.','Banco de Servicios y Transacciones S.A.','RCI Banque','BACS Banco de Crédito y Securitización S.A.',
                        'Banco Masventas S.A.','Nuevo Banco de Entre Ríos S.A.','Banco Columbia S.A.','Banco Bica S.A.','Banco Coinag S.A.','Ford Credit Compañía Financiera S.A.',
                        'BANCO DE COMERCIO','Compañía Financiera Argentina S.A.','Volkswagen Credit Compañía Financiera S.A.','Cordial Compañía Financiera S.A.',
                        'Fiat Crédito Compañía Financiera S.A.','GPAT Compañía Financiera S.A.','Mercedes-Benz Compañía Financiera Argentina S.A.',
                        'Rombo Compañía Financiera S.A.','John Deere Credit Compañía Financiera S.A.','PSA Finance Argentina Compañía Financiera S.A.',
                        'Toyota Compañía Financiera de Argentina S.A.','Finandino Compañía Financiera S.A.','Montemar Compañía Financiera S.A.',
                        'Multifinanzas Compañía Financiera S.A.','Caja de Crédito Cooperativa La Capital del Plata Limitada','Caja de Crédito “Cuenca" Cooperativa Limitada'];
    
  }
  public intializePublicServices(){
    this.itemsSelected = ['Epec','Ecogas','Aguas Cordobesas','Registro de la Propiedad de la Provincia de Córdoba',
                          'DNRPA Dirección Nacional de Registros de la Propiedad Automotor','Informe de Situación Laboral/provisional',
                          'DGR Dirección General de Rentas de la Provincia de Córdoba', 'Municipalidad de Córdoba'];

  }
  public intializePrivateServices(){
    this.itemsSelected = ['Movistar','Personal','Cablevisión','Telecom','Fibertel','Claro','Obra Social'];

  }
  openModal() {
    var element = document.getElementById("container");
    element.classList.add("mystyle");
  }

  closeModal() {
    var element = document.getElementById("container");
    element.classList.remove("mystyle");
    }

}
