<div class="card">
  <div class="card-header">
    <!-- <div class="px-logo">
      <a href="https://www.positronx.io" target="_blank">
        <img src="https://www.positronx.io/wp-content/themes/positronx/img/logo-positronx-white.svg"
          alt="positronX.io - Learn Full Stack Development">
      </a>
    </div> -->

    <div class="authBlock">
      <h3>Crear Cuenta</h3>

      <div class="formGroup">
        <input type="email" class="formControl" placeholder="Direccion email" #userEmail required>
      </div>

      <div class="formGroup">
        <input type="password" class="formControl" placeholder="Contraseña" #userPwd required>
      </div>

      <div class="formGroup">
        <input type="button" class="btn btnPrimary " value="Crear Cuenta"
          (click)="authService.SignUp(userEmail.value, userPwd.value)">
      </div>

      <!-- Continue with Google -->
      <div class="formGroup">
        <button type="button" class="btn btnStyle" (click)="authService.GoogleAuth()">
          <i class="fab fa-google-plus-g"></i>
          Continuar con google
        </button>
      </div>

    </div>

    <div class="redirectToLogin">
      <span>Ya tenes una cuenta? <span class="redirect" routerLink="/sign-in">Entrar</span></span>
    </div>
  </div>
</div>