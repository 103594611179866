import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-steps',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.styl']
})
export class StepsLineComponent {
  @Input() steps: string[]
  @Input() current: number
}
