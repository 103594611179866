import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.styl']
})
export class HomeComponent implements OnInit {

  public isSpanish = true;
  constructor() { }

  ngOnInit(): void {  }

  public procesaPropagar(event){
    this.isSpanish = !this.isSpanish
  }
}
