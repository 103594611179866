<div class="displayTable">
  <div class="displayTableCell">

    <!-- <div class="px-logo">
      <a href="https://www.positronx.io" target="_blank">
        <img src="https://www.positronx.io/wp-content/themes/positronx/img/logo-positronx-white.svg" alt="positronX.io - Learn Full Stack Development">
      </a>
    </div> -->

    <div class="authBlock">
      <h3>Gracias por verificar tu cuenta</h3>

      <div class="formGroup" *ngIf="authService.userData as user">
        <p class="text-center">Enviamos un email de confirmacion a  <strong>{{user.email}}</strong>.</p>
        <p class="text-center">Por favor, verifica tu mail y hace click en el link para verificar tu cuenta</p>
      </div>

      <!-- Calling SendVerificationMail() method using authService Api -->
      <div class="formGroup">
        <button type="button" class="btn btnPrimary" (click)="authService.SendVerificationMail()">
          <i class="fas fa-redo-alt"></i>
          Reenviar mail de verificacion
        </button>
      </div>

    </div>

    <div class="redirectToLogin">
      <span>Volver a <span class="redirect" routerLink="/sign-in"> Crear cuenta</span></span>
    </div>

  </div>
</div>
