<ng-template #contenido let-modal let-c="close" let-d="dismiss">
    <div id="myModal" class="Modal is-hidden is-visuallyHidden bd-example-modal-xl" >
        <div class="modal-header">
            <h4 *ngIf="type!=4 && isSpanish" class="modal-title">Lista de {{title}}:</h4>
            <h4 *ngIf="type!=4 && !isSpanish" class="modal-title">List of {{title}}:</h4>
            <h4 *ngIf="type==4" class="modal-title">Filosofía POSCALM</h4>

            <button class="close" aria-label="close" type="button" (click)="modal.dismiss();closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngFor="let items of itemsSelected">
                <div class="row">
                    <div class="col-2">-</div>
                    <div class="col-10 text-left pt-1" style="font-size: 16px;font-family: aileronregular;">
                        <span>{{items}}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="type==4">
                <img class="logo mr-3 mb-1 flag-icon" src="../../assets/images/poscalm-19.png" style="width:75%">
                <h3>Misión</h3>
                <p class="text-left">Te asistimos y ayudamos en un momento difícil, integrando múltiples gestiones, valorando tu 
                    tiempo y actuando responsablemente, siempre con el compromiso de acompañarte.
                </p>
                <h3>Visión</h3>
                <p class="text-left">Ser sinónimo de gestion eficiente
                    con calidez en atencion a la circunstancia.
                </p>
                <h3>Valores</h3>
                <p class="text-left">Valoramos el tiempo: Ocupate de vos... nosotros del resto
                </p>
                <h3>Respeto</h3>
                <p class="text-left">Como base de cualquier relacion
                </p>
                <h3>Empatía</h3>
                <p class="text-left">Lo sabemos por estamos aqui. La empatia es el alma de PosCalm
                </p>
                <h3>Confianza</h3>
                <p class="text-left">Cumplimos con lo que ofrecemos
                </p>
            </div>
        </div>
        <div class="modal-footer">
            <button *ngIf="isSpanish" type="button" class="btn contact" (click)="modal.close();closeModal()">Continuar</button>
            <button *ngIf="!isSpanish" type="button" class="btn contact" (click)="modal.close();closeModal()">Continue</button>
        </div>
    </div>

</ng-template>
<a *ngIf="type!=4 && isSpanish" class="ml-4" style="font-weight: normal;color: #7e8085;font-size: 16px;" (click)="modal.open(contenido);openModal()" id="myBtn">Consultá la
    lista
    completa.</a>
<a *ngIf="type!=4 && !isSpanish" class="ml-4" style="font-weight: normal;color: #7e8085;font-size: 16px;" (click)="modal.open(contenido);openModal()" id="myBtn">See the full list.
</a>
<li *ngIf="type==4" class="mb-3" (click)="modal.open(contenido);openModal()">{{title}}</li>

