import { Injectable, NgZone, ɵConsole } from '@angular/core';
import { User } from '../services/user';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  uId : any;
  public loading = true
  public loadingUser = false

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public userService: UserService,
    ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.getUserData();
  }

  getUserData(){
    return new Promise(resolve => {
      this.afAuth.authState.subscribe(user => {
        if (user) {
          this.userData = user;
          this.uId = user.uid;
          console.log(this.uId);
          localStorage.setItem('user', JSON.stringify(this.userData));
          JSON.parse(localStorage.getItem('user'));
          // this.userService.getUserLastStep(this.uId)
          //   .subscribe ( result => {
          //     if (result.location === 'sing-in'){
          //       this.router.navigate(['/sign-in', { step: result.step, identify:  result.uIdRelatedFamily}]);
          //     }
          //     if (result.location === 'enrollment'){
          //       this.router.navigate(['/enrollment/' + result.uIdRelatedFamily, {step: result.step} ]);
          //     }
          //   });
        } else {
          localStorage.setItem('user', null);
          JSON.parse(localStorage.getItem('user'));
        }
      })
    });
  }
  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          // this.router.navigate(['dashboard']);
        });
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
    .then(() => {
      this.router.navigate(['verify-email-address']);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    this.loadingUser = true
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
      return this.afAuth.auth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          setTimeout(() => {
            this.userService.getUserLastStep(this.uId)
            .subscribe ( result => {
              this.getStep(result)
            });         
          }, 5000);
        })
        this.SetUserData(result.user)
      }).catch((error) => {
        window.alert(error)
      })

  }
  public isLoadingUser(){
    return this.loadingUser
  }
  public getStep(result){
    this.loadingUser = false
    if (result.location === 'sing-in'){
      this.router.navigate(['/sign-in', { step: result.step, identify:  result.uIdRelatedFamily}]);
    }
    if (result.location === 'enrollment'){
      this.router.navigate(['/enrollment/' + result.uIdRelatedFamily, {step: result.step} ]);
    }
    this.loading = false;
  }
  getUser(){
    return this.uId;
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uId: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  // Sign out 
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    });
  }
}