import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';

import * as AOS from 'aos';
import { AuthService } from './shared/services/auth.service';
import { UserService } from './shared/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'home';
  public home: boolean;
  private userId: string;
  collection_status: string;
  collection_id: string;
  identify: string;
  public params
  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    public userService: UserService,
    private router: Router,

  ){ }

  ngOnInit(){
    AOS.init();
    this.params = this.route.snapshot.queryParamMap
    console.log(this.params)

  }
  ngAfterViewInit(){

  }


  public getUserData(){
    console.log("Asd")
    this.route.queryParams.subscribe(params => {
      console.log(params)
      this.collection_status = params['collection_status'];
      this.collection_id = params['collection_id'];
      console.log(this.collection_status)
      this.makeADecision();
    });
  }
  public makeADecision(){
    if(this.authService.isLoggedIn){
      setTimeout(() => {
        this.userId = this.authService.getUser();
      }, 2000);
    }

  }

  async getUserIdWhenItIsReady() {
    const remainder = this.authService.getUserData();
    console.log(this.userId);
    }
}
