import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class MercadoPagoService{
  private url = environment.apiUrl;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(private http: HttpClient) {}

  public getPayment(idRelatedFamily, code): Observable<any> {
    return this.http.post(`${this.url}procesar-pago`,{idRelatedFamily: idRelatedFamily, code: code}, this.httpOptions)
  }
}

