import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { FirebaseStorageService } from '../../firebase-storage.service';
import * as moment from 'moment';

import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { isString } from 'util';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss']
})

export class EnrollmentComponent implements OnInit, AfterViewInit {
  public steps: string[]
  public nameRelatedFamily: any
  public step =1;
  public stepDone =1;
  public wichQuestion
  public isShowing = false
  public form: FormGroup
  public isShowingOptions
  public uId : any
  public userData : any
  public loading : Boolean
  public loadingName = true
  public idRelatedFamily : any
  public currentUserRelatedFamily 
  public fullUserData : any
  public porcentaje = 0;
  public finalizado = false;
  public uploadDone: any
  public dniBackDead: any
  public dniFrontDead: any
  public dniBackUser: any
  public dniFrontUser: any
  public uploadAnotherFile: any
  public uploadError = false;
  selectedFile = null;
  selectedFileName : any
  URLPublica : any
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  fileData: File = null;
  previewUrl2:any = null;
  previewUrl3:any = null;
  previewUrl4:any = null;
  previewUrl5:any = null;
  previewUrl6:any = null;

  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  constructor(
    private firebaseStorage: FirebaseStorageService,
    public authService: AuthService,
    public userService: UserService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private http : HttpClient,
    private router: Router

  ) { 
    this.idRelatedFamily = this.route.snapshot.params.idRelatedFamily
    const stepRouting = this.route.snapshot.paramMap.get('step');
    if(stepRouting){
      setTimeout(() => {
        this.step = this.stepDone = Number(stepRouting);
      }, 250);
    }
    this.loading = false
    this.uploadDone = false;
    this.dniBackDead = false;
    this.dniFrontDead = false;
    this.dniBackUser = false;
    this.dniFrontUser = false;
    this.uploadAnotherFile = false;
    this.form = this.newForm(fb),
    this.isShowingOptions = false
    this.fullUserData = JSON.parse(localStorage.getItem('user'));
    console.log(this.fullUserData)
    this.uId = this.fullUserData.uid
    console.log(this.uId)
      this.currentUserRelatedFamily ={
        uId : this.uId,
        idRelatedFamily : this.idRelatedFamily
      }

      this.userService.getUserLastStep(this.uId)
        .subscribe( result => {
          console.log(result)
        });

      this.userService.postUserIdRelatedFamily(this.currentUserRelatedFamily)
        .subscribe( result => {
          this.form.controls.name.setValue(result.relatedFamily.fullName)
          this.nameRelatedFamily = result.relatedFamily.fullName
          this.loadingName=false
      });  


  }

  ngOnInit() {    

    this.uId = this.authService.getUser()
    this.steps = ['', '', '','','','','','']

  }
  ngAfterViewInit(){
    const stepRouting = this.route.snapshot.paramMap.get('step');
    if(stepRouting){
      setTimeout(() => {
        this.step = this.stepDone = Number(stepRouting);
      }, 250);
    }
  }
  nextStep(){
    if(this.step>=this.stepDone) this.stepDone+=1
    this.step += 1
    this.router.navigate(['/enrollment/' + this.idRelatedFamily, {step: this.step}]);

  }
  nextStepUpload(){
    this.step += 1
    if(this.step>this.stepDone){
      this.stepDone++;
    }
    this.router.navigate(['/enrollment/' + this.idRelatedFamily, {step: this.step}]);

  }

  public thisFileUpload() {
    document.getElementById("file").click();
  }
  private newForm(fb): FormGroup {
    let typeControl = fb.control('', Validators.required)
    return fb.group({
        name: fb.control('',[Validators.required,Validators.minLength(6)]),
        tipoDoc: fb.control('',Validators.required),
        dni: fb.control('',[Validators.required,Validators.minLength(6)]),
        caracteristica: fb.control('',[Validators.required,Validators.minLength(3)]),
        telefono: fb.control('',[Validators.required,Validators.min(50)]),
    })
  }
  showQuestion( ques ){
    if(this.wichQuestion == ques)
    {
      this.isShowing = false
      this.wichQuestion = 0
    }
    else{
      this.isShowing = true;
      this.wichQuestion = ques
    }
  }
  showOptions(){
    if(this.isShowingOptions == false){
      this.isShowingOptions = true
    }else this.isShowingOptions = false
  }

  public sendUserData(){
    this.loading = true
    this.uId = this.authService.getUser()
    this.userData = {
      relatedFamily : { 				
        numberDocument: this.form.controls.dni.value, 				
        typeDocument: this.form.controls.tipoDoc.value,
        idRelatedFamily: this.idRelatedFamily
      },
      uId : this.uId,
      phoneNumber : this.form.controls.caracteristica.value + this.form.controls.telefono.value 			
    }
    this.userService.putUserFullData(this.userData)
      .subscribe( result => {
        this.loading = false
        if(this.step>=this.stepDone) this.stepDone+=1
        this.step += 1
      });
  }
 
  fileProgress(event) {
      this.fileData = <File>event.target.files[0];
      this.preview();
      this.selectedFile = event.target.files[0]

  }

  preview() {
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
  
      var reader = new FileReader();      
      reader.readAsDataURL(this.fileData); 
      reader.onload = (_event) => { 
        if(this.step===2)this.previewUrl2 = reader.result; 
        if(this.step===3)this.previewUrl3 = reader.result; 
        if(this.step===4)this.previewUrl4 = reader.result; 
        if(this.step===5)this.previewUrl5 = reader.result; 
        if(this.step===6)this.previewUrl6 = reader.result; 

      }
  }
  onSubmit() {
    const formData = new FormData();
      formData.append('file', this.fileData);
      // this.http.post('url/to/your/api', formData)
      //   .subscribe(res => {
      //     console.log(res);
      //     this.uploadedFilePath = res.data.filePath;
      //     alert('SUCCESS !!');
      //   })
      }
      changeFile(){
        this.fileData.slice(0)
      }
  public cambioArchivo(event) {
    this.fileData = <File>event.target.files[0];
    this.preview();
    this.selectedFile = event.target.files[0]
    this.selectedFileName = event.target.files[0].name;
    if(this.step===2) this.uploadDone = false;
    if(this.step===3) this.dniFrontDead = false;
    if(this.step===4) this.dniBackDead = false;
    if(this.step===5) this.dniFrontUser = false;
    if(this.step===6) this.dniBackUser = false;
  }
  public subirArchivo() {
    this.loading = true
    let referencia = this.firebaseStorage.referenciaCloudStorage(this.selectedFileName);
    this.firebaseStorage.tareaCloudStorage(this.selectedFileName,  this.selectedFile ).then(
      (response) => {
        if(this.step>=this.stepDone) this.stepDone+=1
        this.finalizado = true;
        if(this.step===2) this.uploadDone = true;
        if(this.step===3) this.dniFrontDead = true;
        if(this.step===4) this.dniBackDead = true;
        if(this.step===5) this.dniFrontUser = true;
        if(this.step===6) this.dniBackUser = true;
        this.loading = false;
        referencia.getDownloadURL().subscribe((URL) => {
          this.URLPublica = URL;
          this.uploadFileRelatedFamily()
        }), error => {
          this.uploadError = true;
          this.loading = false
        }
      }
    );
  }
  public uploadFileRelatedFamily(){

    var uploadFileData
    if(this.step === 2 ) {
      uploadFileData = {
        uId: this.authService.getUser(),
        relatedFamily : {
          id: this.idRelatedFamily,
          fileUrl:  this.URLPublica
        },
        step: this.step 
      }
    }
    if(this.step === 3 ) {
      uploadFileData = {
        uId: this.authService.getUser(),
        relatedFamily : {
          id: this.idRelatedFamily,
          dniFrontDead:  this.URLPublica
        },
        step: this.step 
      }
    }
    if(this.step === 4 ) {
      uploadFileData = {
        uId: this.authService.getUser(),
        relatedFamily : {
          id: this.idRelatedFamily,
          dniBackDead:  this.URLPublica
        },
        step: this.step 
      }
    }
    if(this.step === 5 ) {
      uploadFileData = {
        uId: this.authService.getUser(),
        relatedFamily : {
          id: this.idRelatedFamily,
          dniFrontUser:  this.URLPublica
        },
        step: this.step 
      }
    }
    if(this.step === 6 ) {
      uploadFileData = {
        uId: this.authService.getUser(),
        relatedFamily : {
          id: this.idRelatedFamily,
          dniBackUser:  this.URLPublica
        },
        step: this.step 
      }
    }
    console.log(uploadFileData)
    this.userService.uploadFile(uploadFileData)
    .subscribe( result => {
      console.log(result)
    });
  }

  public goBack(){
    this.step -=1
  }

  public currentDate() {
    var d = new Date();
    d.setDate(d.getDate() + 5);
    const dateAndTime = moment(d).format('DD/MM/YY');
    return dateAndTime
  }
}