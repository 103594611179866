<div class="card">
  <div class="card-header">
    Recuperar contraseña
  </div>
  <div class="card-body">
 

      <p class="text-center">Por favor, ingresa tu cuenta de email para recuperar tu contraseña</p>

      <div class="formGroup">
        <input type="email" class="formControl" placeholder="Email Address" #passwordResetEmail required>
      </div>

      <!-- Calling ForgotPassword from AuthService Api -->
      <div class="formGroup">
        <input type="submit" class="btn btnPrimary" value="Reset Password" (click)="authService.ForgotPassword(passwordResetEmail.value)">
      </div>
  </div>

    <div class="redirectToLogin">
      <span class="redirect" routerLink="/sign-in">Volver a Log in</span>
    </div>
</div>
