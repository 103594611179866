import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.styl']
})
export class NavComponent implements OnInit {
  @Input() spanish: boolean;
  @Output() propagar = new EventEmitter<boolean>();
  public isCollapsed = false;
  public show = false;
  public isSpanish = true
  closeResult: string;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void { 
    document.addEventListener('click', this.printMousePos, true);

  }

  toggleMenu() {
     this.isCollapsed = !this.isCollapsed;
   }

   public changeLanguage(){
    this.isSpanish = !this.isSpanish
    this.propagar.emit(this.isSpanish);
    document.getElementById("mySidenav").style.width = "0";
    if(screen.width < 600){
      setTimeout(function(){document.getElementById("button-toggler").style.display = "block" }, 300);
    }else{
      document.getElementById("button-toggler").style.display = "none";
    }
    document.getElementById("closebtn").style.display = "none";
   }

  public openNav() {
    document.getElementById("mySidenav").style.width = "70%";
    document.getElementById("button-toggler").style.display = "none";
    document.getElementById("closebtn").style.display = "block";
  }

  /* Set the width of the side navigation to 0 */
  public closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("closebtn").style.display = "none";
    setTimeout(function(){document.getElementById("button-toggler").style.display = "block" }, 300);
  }

  public printMousePos(e) {
    var cursorX = e.pageX;
    var cursorY = e.pageY;
    var sizeSideNav = document.getElementById("mySidenav").offsetHeight;
    if(cursorX > (screen.width*70)/100 || cursorY > sizeSideNav){
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("closebtn").style.display = "none";
      if(screen.width<600){
        setTimeout(function(){document.getElementById("button-toggler").style.display = "block" }, 300);
      }
    }
  }

  openBackDropCustomClass(content) {
    this.modalService.open(content, {backdropClass: 'light-blue-backdrop'});
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal' });
  }

  openSm(content) {
    this.modalService.open(content, { size: 'sm' });
  }

  openLg(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  openXl(content) {
    this.modalService.open(content, { size: 'xl' });
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  openScrollableContent(longContent) {
    this.modalService.open(longContent, { scrollable: true });
  }

  openModal() {
    var element = document.getElementById("container");
    element.classList.add("mystyle");
  }

  closeModal() {
    var element = document.getElementById("container");
    element.classList.remove("mystyle");
    }
}
