// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDX5pI3CviipMUfIWCNPR102OKGaJR9X0s',
    authDomain: 'testlogin-527f2.firebaseapp.com',
    databaseURL: 'https://testlogin-527f2.firebaseio.com',
    projectId: 'testlogin-527f2',
    storageBucket: 'testlogin-527f2.appspot.com',
    messagingSenderId: '1017991214065',
    appId: '1:1017991214065:web:c252c6cfc159a75c420a50'
  },
  apiUrl : 'https://us-central1-testlogin-527f2.cloudfunctions.net/app/api/'
  // apiUrl :'http://localhost:5001/testlogin-527f2/us-central1/app/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
