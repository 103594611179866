<main>
  <div class="row first-section no-margin">
    <div class="col-md-8 offset-md-2" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
      <div class="row no-margin ">
        <span *ngIf="isSpanish" class="top-text-why white-background mb-4">¿CÓMO FUNCIONA?</span>
        <span *ngIf="!isSpanish" class="top-text-why white-background mb-4">¿HOW DOES IT WORK?</span>
      </div>
      <h1 class="col-md-8 offset-md-2 mb-5 subtitle-mobile">
        <span *ngIf="isSpanish">Nos encargamos de todo para que puedas estar con tus seres
          queridos</span>
        <span *ngIf="!isSpanish">We take care of everything so you can be with your loved ones</span></h1>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-12 col-md-3 offset-md-2 text-left mobile-center" data-aos="fade-down" data-aos-duration="1000">
          <div class="icon-why-section mb-2">
            <img class="icon-how mb-3" src="../../assets/icons/stats.svg">
          </div>
          <div class="how-tittle ">
            <span *ngIf="isSpanish">Averiguamos de qué bienes y servicios era titular esa persona</span>
            <span *ngIf="!isSpanish">We found out what goods and services that person owned</span>
          </div>
          <p *ngIf="isSpanish" class="why-text mt-4">Tenemos una amplia lista de empresas con la que trabajamos</p>
          <p *ngIf="!isSpanish" class="why-text mt-4">We have an extensive list of companies we work with.</p>
          <div>
          </div>
        </div>

        <div class="col-12 col-md-3 text-left mobile-center" data-aos="fade-down" data-aos-duration="1800">
          <div class="icon-why-section mb-2">
            <img class="icon-how mb-3" src="../../assets/icons/contract.svg">
          </div>
          <div class="how-tittle">
            <span *ngIf="isSpanish">Preparamos un reporte para que puedas decidir con toda la información</span>
            <span *ngIf="!isSpanish">We prepare a report so you can decide with all the information</span>
          </div>
          <p *ngIf="isSpanish" class="why-text mt-4 ">En el informe detallamos las empresas y servicios contratados
            junto con
            recomendaciones para evitar deudas y posibles contratiempos a futuro.</p>
          <p *ngIf="!isSpanish" class="why-text mt-4 ">We assign you a personal advisor. In addition, we can give you
            legal advice* to
            speed up the inheritance process.</p>
          <div>
          </div>
        </div>
        <div class="col-12  col-md-3 text-left mobile-center" data-aos="fade-down" data-aos-duration="2600">
          <div class="icon-why-section mb-2">
            <img class="icon-how mb-3" src="../../assets/icons/briefcase.svg">
          </div>
          <div class="how-tittle">
            <span *ngIf="isSpanish">Realizamos los trámites que elijas dentro de esa lista de empresas</span>
            <span *ngIf="!isSpanish">We carry out the procedures that you choose within this list of companies
            </span>
          </div>
          <p *ngIf="isSpanish" class="why-text mt-4 ">Te asignamos un asesor personalizado. Además, podemos darte <a
              href="#legales" style="font-size:16px"> asesoramiento legal* </a>para agilizar los trámites de la
            herencia.
            <br>
            *A cotizar según caso
          </p>
          <p *ngIf="!isSpanish" class="why-text mt-4 ">We assign you a personal advisor. In addition, we can give you to
            speed up the inheritance process
            <a href="#legales" style="font-size:16px"> legal advice* </a>
            <br>
            *To be quoted according to the case
          </p>
          <div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <div id="service">
    <div class="mt-5">
      <div class="col-md-8 offset-md-2" data-aos-duration="1000" data-aos="fade-down" data-aos-delay="0">
        <div class="col-8 offset-2 pt-5 mb-4">
          <span *ngIf="isSpanish" class="top-text-why bg-grey mt-5">¿QUÉ INCLUYE EL SERVICIO?</span>
          <span *ngIf="!isSpanish" class="top-text-why bg-grey mt-5">¿WHAT DOES THE SERVICE INCLUDE?</span>

        </div>
        <h1 *ngIf="isSpanish" class="mb-4">Trabajamos con estas empresas</h1>
        <h1 *ngIf="!isSpanish" class="mb-4">We work with these companies</h1>
        <h2 *ngIf="isSpanish" class="col-md-8 offset-md-2">Nos encargamos de buscar y conseguir la información detallada
          en todas las empresas que vas a ver a continuación.</h2>
        <h2 *ngIf="!isSpanish" class="col-md-8 offset-md-2">We take care of searching and getting the detailed
          information
          on all the companies you will see below.</h2>
      </div>
      <div class="col-md-8 offset-md-2 mt-1">
        <div class="row">
          <nav ngbNav #nav="ngbNav" class="nav-tabs">
            <ng-container ngbNavItem>
              <a *ngIf="isSpanish" ngbNavLink class="height-tab col-md-3 col-3 offset-0" data-aos-duration="1000"
                data-aos="fade-down" data-aos-delay="0"><img class="icon-nabs mb-1"
                  src="../../assets/icons/003-credit-card.svg">Bancos
                y tarjetas</a>
              <a *ngIf="!isSpanish" ngbNavLink class="height-tab col-md-3 col-3 offset-0 tab-eng" data-aos-duration="1000"
                data-aos="fade-down" data-aos-delay="0"><img class="icon-nabs mb-1"
                  src="../../assets/icons/003-credit-card.svg"> Banks and cards 
                  </a>
              <ng-template ngbNavContent>
                <div class="col-md-12 col-12" data-aos="flip-left" data-aos-duration="1000">
                  <div class="row row-tabs">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-6 offset-md-0 offset-1  col-5 pr-0 mr-2">
                          <img class="img-navs mb-3" src="../../assets/images/banking-card@3x.jpg">
                        </div>
                        <div class="col-md-5 col-5 pl-0">
                          <img class="img-navs mb-3" src="../../assets/images/banking-card@32.jpg">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 offset-md-0 offset-1  col-5 pr-0 mr-2">
                          <img class="img-navs mb-1" src="../../assets/images/banking-card@31.jpg">
                        </div>
                        <div class="col-md-5 col-5 pl-0">
                          <img class="img-navs mb-1" src="../../assets/images/banking-card@33.jpg">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-12 offset-1   mt-2 pl-0 ml-0 link-section-2">
                          <app-modal *ngIf="isSpanish" customTitle="Bancos" modalType="1" [spanish]="isSpanish"></app-modal>
                          <app-modal *ngIf="!isSpanish" customTitle="Banks" modalType="1" [spanish]="isSpanish"></app-modal>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 text-left">
                      <p *ngIf="isSpanish" class="tittle-p mb-5">Bancos y tarjetas</p>
                      <p *ngIf="!isSpanish" class="tittle-p mb-5">Banks and cards</p>
                      <p class="description-p col-md-10 col-10 offset-1">
                        <li *ngIf="isSpanish" style="list-style: none;">Suele ocurrir que los bancos no se enteran del fallecimiento y el
                          dinero
                          queda en la cuenta por mucho tiempo debido a que nadie lo reclama.</li>
                          <li *ngIf="!isSpanish" style="list-style: none;">It often happens that the banks do not find out about the death 
                            and the money remains in the account for a long time because no one claims it.</li>
                      </p>
                      <p class="description-p col-md-10 col-10 offset-1">
                        <li *ngIf="isSpanish" style="list-style: none;">Es importante que puedas revisar las cuentas para ver que no quede
                          perdido dinero de jubilaciones o si tenia saldo deudor, que no siga generando intereses.
                        </li>
                        <li *ngIf="!isSpanish" style="list-style: none;">It is important that you can check the accounts to see that no 
                          retirement money is lost or if he had a balance due, that it does not continue to generate interest.
                        </li>
                      </p>
                      <div class="col-12">
                        <hr class="new1">
                      </div>
                      <div class="row">
                        <div class="col-2 col-mobile">
                          <img class="icon-persona mb-1" src="../../assets/images/ropic.png">
                        </div>
                        <div class="col-10 text-left">
                          <p *ngIf="isSpanish" style="color: #71769e;" class="testimonial">“Mi abuelo contaba con una caja de ahorro en
                            pesos y otra en
                            dólares que no conocíamos. El dinero nos ayudó mucho con los gastos del entierro y la
                            funeraria.”
                          </p>
                          <p *ngIf="!isSpanish" style="color: #71769e;" class="testimonial">"My grandfather had a savings account in 
                            pesos and another in dollars that we didn't know about. The money helped us a lot with the burial and funeral expenses."
                          </p>
                        </div>
                      </div>
                      <div class="col-10 offset-2 text-left">
                        <p class="text-testimony"> Andrea Perez, Córdoba.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
            <ng-container ngbNavItem>
              <a *ngIf="isSpanish" ngbNavLink class="height-tab col-md-3 offset-md-1 col-3 offset-0" data-aos-duration="1000"
                data-aos="fade-down" data-aos-delay="0"><img class="icon-nabs mb-1"
                  src="../../assets/icons/010-document.svg">Servicios públicos</a>
                  <a *ngIf="!isSpanish" ngbNavLink class="height-tab col-md-3 offset-md-1 col-3 offset-0 tab-eng" data-aos-duration="1000"
                  data-aos="fade-down" data-aos-delay="0"><img class="icon-nabs mb-1"
                    src="../../assets/icons/010-document.svg">Utilities</a>
              <ng-template ngbNavContent>
                <div class="col-md-12 col-12" data-aos="flip-left" data-aos-duration="1000">
                  <div class="row row-tabs">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-6 offset-md-0 offset-1  col-5 pr-0 mr-2">
                          <img class="img-navs mb-3" src="../../assets/images/banking-card@3x2.jpg">
                        </div>
                        <div class="col-md-5 col-5 pl-0">
                          <img class="img-navs mb-3" src="../../assets/images/banking-card@324.jpg">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 offset-md-0 offset-1  col-5 pr-0 mr-2">
                          <img class="img-navs mb-1" src="../../assets/images/banking-card@3-213.jpg">
                        </div>
                        <div class="col-md-5 col-5 pl-0">
                          <img class="img-navs mb-1" src="../../assets/images/banking-card@3x3.jpg">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-12 offset-1   mt-2 pl-0 ml-2 link-section-2">
                          <app-modal *ngIf="isSpanish" customTitle="Servicios Publicos" modalType="2" [spanish]="isSpanish"></app-modal>
                          <app-modal *ngIf="!isSpanish" customTitle="Utilities" modalType="2" [spanish]="isSpanish"></app-modal>

                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 text-left">
                      <p *ngIf="isSpanish" class="tittle-p mb-5">Servicios públicos de Córdoba</p>
                      <p *ngIf="!isSpanish" class="tittle-p mb-5">Córdoba Utilities </p>

                      <p *ngIf="isSpanish" class="description-p col-md-10 col-10 offset-1">
                        <li style="list-style: none;">Los servicios públicos tienen relevancia cuando se piensa en
                          propiedad horizontal.</li>
                      </p>
                      <p *ngIf="!isSpanish" class="description-p col-md-10 col-10 offset-1">
                        <li style="list-style: none;">Utilities are relevant when thinking about condominiums.</li>
                      </p>
                      <p *ngIf="isSpanish" class="description-p col-md-10 col-10 offset-1">
                        <li style="list-style: none;">Asimismo, pueden generarse deudas en caso de que no reciban un
                          aviso para dar de baja el servicio que están ofreciendo.</li>
                      </p>
                      <p *ngIf="!isSpanish" class="description-p col-md-10 col-10 offset-1">
                        <li style="list-style: none;">Also, if a notice of termination is not received, debts may 
                          be generated in the event that the utilities being offered are not cancelled.</li>
                      </p>
                      <div class="col-10">
                        <hr class="new1">
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <img class="icon-persona mb-1" src="../../assets/images/persona.png">
                        </div>
                        <div class="col-10 text-left">
                          <p *ngIf="isSpanish" style="color: #71769e;" class="testimonial">”Nunca dimos de baja los servicios de la casa
                            de mi papá cuando
                            falleció. Dos años después, cuando salió la sucesión, la pusimos en venta y nos enteramos de
                            que la deuda era enorme.” </p>
                            <p *ngIf="!isSpanish" style="color: #71769e;" class="testimonial">"We never shut off the utilities in my dad's
                               house when he died. Two years later, when the succession came out, we put it up for sale and found out that the 
                               debt was huge.”
                            </p>
                        </div>
                      </div>
                      <div class="col-10 offset-2 text-left">
                        <p class="text-testimony"> Fernando Lacentra, Córdoba.</p>
                      </div>
                    </div>
                  </div>
                </div>

              </ng-template>
            </ng-container>
            <ng-container ngbNavItem>
              <a *ngIf="isSpanish" ngbNavLink class="height-tab col-md-3 offset-md-1 col-3 offset-0" data-aos-duration="1000"
                data-aos="fade-down" data-aos-delay="0"><img class="icon-nabs mb-1"
                  src="../../assets/icons/009-credit-card.svg">Servicios privados</a>
                  <a *ngIf="!isSpanish" ngbNavLink class="height-tab col-md-3 offset-md-1 col-3 offset-0 tab-eng" data-aos-duration="1000"
                  data-aos="fade-down" data-aos-delay="0"><img class="icon-nabs mb-1"
                    src="../../assets/icons/009-credit-card.svg">Private services </a>
              <ng-template ngbNavContent>
                <div class="col-md-12 col-12" data-aos="flip-left" data-aos-duration="1000">
                  <div class="row row-tabs">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-6 offset-md-0 offset-1  col-5 pr-0 mr-2">
                          <img class="img-navs mb-3" src="../../assets/images/banking-card@3cable.jpg">
                        </div>
                        <div class="col-md-5 col-5 pl-0">
                          <img class="img-navs mb-3" src="../../assets/images/banking-card@3personal.jpg">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 offset-md-0 offset-1  col-5 pr-0 mr-2">
                          <img class="img-navs mb-1" src="../../assets/images/banking-card@3claro2.jpg">
                        </div>
                        <div class="col-md-5 col-5 pl-0">
                          <img class="img-navs mb-1" src="../../assets/images/banking-card@3fiertel.jpg">
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12 col-12 offset-1   mt-2 pl-0 ml-2 link-section-2">
                          <app-modal *ngIf="isSpanish" class="modal-xl" customTitle="Servicios Privados" modalType="3"
                            data-backdrop="static" data-keyboard="false" [spanish]="isSpanish"></app-modal>
                          <app-modal *ngIf="!isSpanish" class="modal-xl" customTitle="Private services" modalType="3"
                            data-backdrop="static" data-keyboard="false" [spanish]="isSpanish"></app-modal>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 text-left">
                      <p *ngIf="isSpanish" class="tittle-p mb-5">Servicios privados</p>
                      <p *ngIf="!isSpanish" class="tittle-p mb-5">Private services</p>
                      <p *ngIf="isSpanish" class="description-p col-md-10 col-10 offset-1">
                        <li style="list-style: none;">Los servicios privados pueden ser un problema si no reciben la
                          baja del servicio y siguen computando deuda. </li>
                      </p>
                      <p *ngIf="!isSpanish" class="description-p col-md-10 col-10 offset-1">
                        <li style="list-style: none;">Private services can be a problem if they are not discharged and
                           continue to compute debt. </li>
                      </p>
                      <p *ngIf="isSpanish" class="description-p col-md-10 col-10 offset-1">
                        <li style="list-style: none;">Gestionarlos de forma temprana, evita dolores de cabeza en un
                          momento donde el foco esta en otro lugar.
                        </li>
                      </p>
                      <p *ngIf="!isSpanish" class="description-p col-md-10 col-10 offset-1">
                        <li style="list-style: none;">Managing these services early avoids headaches at a time 
                          when the focus is elsewhere.
                        </li>
                      </p>
                      <div class="col-10">
                        <hr class="new1">
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <img class="icon-persona mb-1" src="../../assets/images/persona2.png">
                        </div>
                        <div class="col-10 text-left">
                          <p *ngIf="isSpanish" style="color: #71769e;" class="testimonial">“Cuando falleció mi abuelo todos estábamos muy
                            tristes y no
                            sabíamos qué trámites hacer. Nos dejamos estar y
                            ahora tenemos un montón de deudas y hasta un juicio. Me arrepiento de no haberme ocupado de
                            esto a tiempo.”</p>
                            <p *ngIf="!isSpanish" style="color: #71769e;" class="testimonial">"When my grandfather passed away, we were all 
                              very sad and didn't know what to do. We let ourselves go and now we have a lot of debts and even a lawsuit. 
                              I regret not having taken care of this in time.”</p>
                        </div>
                      </div>
                      <div class="col-10 offset-2 text-left">
                        <p class="text-testimony">Ricardo Antonucci, Córdoba.</p>
                      </div>
                    </div>
                  </div>
                </div>

              </ng-template>
            </ng-container>
          </nav>

          <div [ngbNavOutlet]="nav" class="mt-2"></div>

        </div>
      </div>
    </div>
  </div>

  <div class="spacer"></div>
  <div id="price">
    <div class="col-md-8 offset-md-2" data-aos-duration="1400" data-aos="fade-down" data-aos-delay="0">
      <div class="row mb-4">
        <span *ngIf="isSpanish" class="top-text-why top-2" style="background: none;">¿CUÁNTO CUESTA?</span>
        <span *ngIf="!isSpanish" class="top-text-why top-2" style="background: none;">¿HOW MUCH DOES IT COST?</span>
      </div>
      <h1 *ngIf="isSpanish">Pagás una vez, accedés a todos los trámites</h1>
      <h1 *ngIf="!isSpanish">You pay once, you get all the paperwork</h1>

      <h2 *ngIf="isSpanish" class="col-10 col-md-10 offset-1 mt-4 h2-price mb-5">
        El monto es fijo sin importar la cantidad de trámites que realicemos del listado de empresas.
        Elegís qué acción tomar (baja o cambio de titularidad) en función del reporte que te brindamos.
        Nuestro equipo se encarga de todo.
      </h2>

      <h2 *ngIf="!isSpanish" class="col-10 col-md-10 offset-1 mt-4 h2-price mb-5">
        The amount is fixed no matter how many procedures we perform from the list of companies. 
        You choose what action to take (cancellation or change of ownership) depending on the report we
         provide you with. Our team takes care of everything.
      </h2>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="card col-12 col-md-3 offset-md-3 mr-3" data-aos="fade-right" data-aos-duration="3000">
          <div class="card-text">
            <span *ngIf="isSpanish">Podés pagar:</span>
            <span *ngIf="!isSpanish">You can pay:</span>

            </div>
          <div class="card-description mb-3">
            <span *ngIf="isSpanish">En hasta 18 cuotas con:</span>
            <span *ngIf="!isSpanish">In up to 18 installments:</span></div>
          <div class="row ml-3">
            <div class="col-2 col-md-3">
              <img class="icon-banks mb-1" src="../../assets/icons/logo-visa.svg">
            </div>
            <div class="col-2 col-md-3 offset-md-1">
              <img class="icon-banks mb-1" src="../../assets/icons/logo-master.svg">
            </div>
            <div class="col-2 col-md-2 offset-md-1">
              <img class="icon-banks mb-1" src="../../assets/icons/logo-american.svg">
            </div>
          </div>
          <div class="row mt-3 mb-2">
            <div class="col-2 col-md-3 offset-md-3">
              <img class="icon-banks mb-1" src="../../assets/icons/cabal.svg">
            </div>
            <div class="col-2 col-md-2 offset-md-1">
              <img class="icon-naranja mb-1" src="../../assets/icons/naranja.jpg">
            </div>
          </div>
          <div class="card-description mb-3">
            <span *ngIf="isSpanish">En efectivo:</span>
            <span *ngIf="!isSpanish">Cash:</span>
          </div>
          <div class="row mt-2 mb-2">
            <div class="col-2 col-md-3 offset-md-3">
              <img class="icon-banks mb-1" src="../../assets/icons/logo-rapipago.svg">
            </div>
            <div class="col-2 col-md-2 offset-md-1">
              <img class="icon-pf mb-1" src="../../assets/icons/pago-f-cil.svg">
            </div>
          </div>
        </div>
        <div class="card-orange col-12 col-md-3" data-aos="fade-left" data-aos-duration="3000">
          <div class="card-price">$ 10.000</div>
          <div class="card-orange-description mt-1">
            <span *ngIf="isSpanish">Pago único:</span>
            <span *ngIf="!isSpanish">One-time payment:</span>
          </div>
          <hr style="border: solid 1px #ee9b8e;">
          <div class="card-items-tittle mt-4 text-left">
            <span *ngIf="isSpanish">El servicio incluye:</span>
            <span *ngIf="!isSpanish">The service includes:</span>
          </div>
          <div *ngIf="isSpanish" class="col-12 col-md-10 offset-md-2 mt-2">
            <div class="card-items">- El informe inicial.</div>
            <div class="card-items">- Gestión de los trámites.</div>
            <div class="card-items">- Asesoramiento personalizado.</div>
          </div>
          <div *ngIf="!isSpanish" class="col-12 col-md-10 offset-md-2 mt-2">
            <div class="card-items">- The initial report.</div>
            <div class="card-items">- Management of the procedures.</div>
            <div class="card-items">- Personalised advice.</div>
          </div>
          <div class="row">
            <div class="col-12 col-md-10 offset-md-1">
              <button onclick="location.href = 'https://poscalm.com/sign-in'" class="contact-2 mt-4">
                <span *ngIf="isSpanish">EMPEZAR AHORA</span>
                <span *ngIf="!isSpanish">START NOW</span>
              </button>
            </div>
          </div>
        </div>
        <div class="card-orange-mobile col-10 offset-1 col-md-3" style="display:none">
          <div class="card-price">$ 10.000</div>
          <hr style="border: solid 1px #ee9b8e;">
          <div class="row">
            <div class="col-3 offset-1 align-self-center">
              <div *ngIf="isSpanish" class="card-items mt-4text-left">Pago único que incluye:</div>
              <div *ngIf="!isSpanish" class="card-items mt-4text-left">One-time payment that includes:</div>

            </div>
            <div *ngIf="isSpanish" class="col-7 mt-3">
              <div class="card-items mb-3">El informe inicial.</div>
              <div class="card-items mb-3">Gestión de los trámites.</div>
              <div class="card-items">Asesoramiento personalizado.</div>
            </div>
            <div *ngIf="!isSpanish" class="col-7 mt-3">
              <div class="card-items mb-3">The initial report.</div>
              <div class="card-items mb-3">Management of the procedures.</div>
              <div class="card-items">Personalised advice.</div>
            </div>
          </div>
          <div class="row">
            <div class="col-10 offset-1 mb-4">
              <button onclick="location.href = 'https://poscalm.com/sign-in'" class="contact-2 mt-4">
                <span *ngIf="isSpanish">EMPEZAR AHORA</span>
                <span *ngIf="!isSpanish">START NOW</span>
              </button>
            </div>
          </div>
        </div>
        <div class="card-mobile col-10 offset-1 col-md-3" style="display:none">
          <div class="row" style="height: 42px;">
            <div *ngIf="isSpanish" class="card-text col-6 offset-1 text-left px-0">En hasta 18 cuotas con:</div>
            <div *ngIf="!isSpanish" class="card-text col-6 offset-1 text-left px-0">In up to 18 installments:</div>
            <div *ngIf="isSpanish" class="card-text col-5 text-left">En efectivo:</div>
            <div *ngIf="!isSpanish" class="card-text col-5 text-left">Cash:</div>

          </div>
          <div class="row mb-2">
            <div class="col-6">
              <div class="row ml-1  ">
                <div class="col-3 offset-1 col-mobile-2">
                  <img class="icon-banks mb-1" src="../../assets/icons/logo-visa.svg">
                </div>
                <div class="col-3 mr-2 ml-2">
                  <img class="icon-banks mb-1 " src="../../assets/icons/logo-master.svg">
                </div>
                <div class="col-3 ml-2">
                  <img class="icon-banks " src="../../assets/icons/logo-american.svg">
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row ml-1  ">
                <div class="col-3 offset-1">
                  <img class="icon-banks mb-1" src="../../assets/icons/logo-rapipago.svg">
                </div>
                <div class="col-3 mr-2 ml-2">
                  <img class="icon-banks mb-1 " src="../../assets/icons/pago-f-cil.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-5 mobile-center">
    <div class="col-md-6 offset-md-3 pt-5" data-aos-duration="1000" data-aos="fade-down" data-aos-delay="0">
      <div class="row mb-4">
        <span *ngIf="isSpanish" class="top-text-why white-background ">¿POR QUÉ HACERLO CON NOSTROS?</span>
        <span *ngIf="!isSpanish" class="top-text-why white-background ">¿WHY DO IT WITH US?</span>

      </div>
      <h1 *ngIf="isSpanish" class="mb-5">Cuidate y cuida a los tuyos, nosotros nos encargamos del resto </h1>
      <h1 *ngIf="!isSpanish" class="mb-5">Take care of yourself and your family, we'll take care of the rest</h1>

    </div>
    <div class="col-md-12">
      <div class="row pb-5">
        <div>
        </div>
        <div class="col-md-3 col-12 offset-md-2 text-center-2" data-aos-duration="800" data-aos="fade-down"
          data-aos-delay="0">
          <div class="icon-why-section mb-4">
            <div id="circle">
              <img class="icon-inside mb-1 mt-3" src="../../assets/images/007-house-8.svg">
            </div>
          </div>
          <div *ngIf="isSpanish" class="why-tittle mb-3">No necesitás salir de tu casa ni renegar con ninguna oficina</div>
          <div *ngIf="!isSpanish" class="why-tittle mb-3">You don't need to leave your house or renege on any office</div>

          <p *ngIf="isSpanish" class="why-text mt-4 ">Manejas todo desde nuestra web, tenés visibilidad de todo lo que ocurre y es súper
            fácil de usar.
          </p>
          <p *ngIf="!isSpanish" class="why-text mt-4 ">You manage everything from our website, you have visibility of everything that happens
             and it is super easy to use.
          </p>
          <div>
          </div>
        </div>

        <div class="col-md-3 col-12 text-center" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          <div class="icon-why-section mb-4 ">
            <div id="circle">
              <img class="icon-inside mb-1 mt-3" src="../../assets/images/018-chat-13.svg">
            </div>
          </div>
          <div *ngIf="isSpanish" class="why-tittle mb-3">Contás con un asesor de cuenta o un abogado asignado</div>
          <div *ngIf="!isSpanish" class="why-tittle mb-3">You have an account manager or a lawyer assigned</div>

          <p *ngIf="isSpanish" class="why-text mt-4">Para que te guíe y realice los trámites por vos. Te acompañamos en todo el
            proceso.</p>

          <p *ngIf="!isSpanish" class="why-text mt-4">To guide you and to make the arrangements for you. We accompany you throughout the process.</p>
          <div>
          </div>
        </div>
        <div class="col-md-3 col-12" data-aos-duration="1200" data-aos="fade-down" data-aos-delay="0">
          <div class="icon-why-section mb-4">
            <div id="circle">
              <img class="icon-inside mb-1 mt-4" src="../../assets/images/031-wallet.svg">
            </div>
          </div>
          <div *ngIf="isSpanish" class="why-tittle mb-3">Tenés cobertura con garantía de reembolso</div>
          <div *ngIf="!isSpanish" class="why-tittle mb-3">You are covered by a money-back guarantee</div>

          <p *ngIf="isSpanish" class="why-text mt-5">Si no podemos realizar los trámites que acordamos, te devolvemos el dinero del
            servicio.</p>
            <p *ngIf="!isSpanish" class="why-text mt-5">If we cannot carry out the procedures we agreed upon, we will return the money for the service.</p>
          <div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="multiple-container">
    <div id="legales">
      <div class="mt-5 ">
        <div class="col-md-8 offset-md-2" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          <div class="row mb-4">
            <span *ngIf="isSpanish" class="top-text-why white-background ">SERVICIOS COMPLEMENTARIOS</span>
            <span *ngIf="!isSpanish" class="top-text-why white-background ">COMPLEMENTARY SERVICES</span>
          </div>
          <h1 *ngIf="isSpanish" class="mb-3">Te ayudamos a seguir adelante</h1>
          <h1 *ngIf="!isSpanish" class="mb-3">We help you move forward</h1>

          <h2 *ngIf="isSpanish" class="col-10 offset-1 col-md-12 offset-md-0 h2-price mb-5">
            Contratando nuestro servicio contás con asesoramiento personalizado para trámites de declaratoria de
            herederos,
            sucesión de bienes, pensiones y seguros. Lo empezás en el acto, sin complicaciones y con el menor costo.
          </h2>
          <h2 *ngIf="!isSpanish" class="col-10 offset-1 col-md-12 offset-md-0 h2-price mb-5">
            By hiring our service, you can count on personalized advice for the declaration of heirs, inheritance, 
            pensions and insurance. You start it right away, without complications and with the lowest cost.
          </h2>
        </div>
      </div>

      <div class="div-image">
        <div class="col-md-12">
          <div class="row">
            <div class="col-9 col-md-6 offset-1 offset-md-1 pl-5 pt-5 text-left">
              <div class="description-text text-left" style="height: auto;width: 90%;" data-aos-duration="600"
                data-aos="fade-down" data-aos-delay="0">
                <span *ngIf="isSpanish">Con nuestro servicio legal vas a agilizar el cobro de herencias, pensiones y seguros.</span>
                <span *ngIf="!isSpanish">With our legal service you will speed up the collection of inheritances, pensions and insurance.</span>

              </div>

            </div>
          </div>
        </div>
        <div class="col-9 col-md-6 offset-1 offset-md-1 mt-3">
          <div class="row-header">
            <div class="col-10 col-md-5 text-left">
              <button onclick="location.href = 'https://api.whatsapp.com/send?phone=5493516156482&text=.'"
                target="_blank" class="contact-3 py-3" data-aos-duration="600" data-aos="fade-down"
                data-aos-delay="0">
                <span *ngIf="isSpanish">CONTACTAR ASESOR</span>
                <span *ngIf="!isSpanish">CONTACT ADVISOR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer-2"></div>

    <div id="questions">
      <div class="col-md-8 offset-md-2" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
        <div class="row mb-4">
          <span *ngIf="isSpanish" class="top-text-why white-background ">¿TENÉS DUDAS?</span>
          <span *ngIf="!isSpanish" class="top-text-why white-background ">¿DO YOU HAVE ANY DOUBTS?</span>
        </div>
        <h1 *ngIf="isSpanish" class="mb-5">Preguntas frecuentes</h1>
        <h1 *ngIf="!isSpanish" class="mb-5">Frequently Asked Questions</h1>

      </div>
      <div class="col-md-12 mb-5">
        <div class="row row-buttons">
          <div class="col-md-5 offset-md-1">
            <div class="row">
              <div class="col-3 offset-1 mr-3">
                <button class="btn-question" disabled>
                  <span *ngIf="isSpanish">Todas</span>
                  <span *ngIf="!isSpanish">All</span>
                </button>
              </div>
              <div class="col-3 ml-2 mr-3">
                <button class="btn-question" disabled>
                  <span *ngIf="isSpanish">Servicios</span>
                  <span *ngIf="!isSpanish">Services</span>
                </button>
              </div>
              <div class="col-3">
                <button class="btn-question" disabled>
                  <span *ngIf="isSpanish">Pagos</span>
                  <span *ngIf="!isSpanish">Payments</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="questions-mobile">
        <div class="col-md-12">
          <div class="row ml-5">
            <div class="col-12 col-md-4 offset-md-1" data-aos="zoom-in-right" data-aos-duration="2000">
              <div class="questions-tittle mb-3">
                <span *ngIf="isSpanish">¿Que documentacion voy a necesitar?</span>
                <span *ngIf="!isSpanish">¿What documentation will I need?</span>
              </div>
              <p *ngIf="isSpanish" class="p-left">Precisás presentar la partida de defunción de ser querido. Este papel es indispensable
                para empezar el informe.
                Dependiendo del resultado puede que solicitemos documentación adicional, pero no suele ser necesario.
              </p>
              <p *ngIf="!isSpanish" class="p-left">You need to present the death certificate of your loved one. This paper is indispensable 
                to start the report. Depending on the outcome, we may request additional documentation, but it is not usually necessary.
              </p>
            </div>
            <div class="col-12 col-md-4 offset-md-1" data-aos="zoom-in-left" data-aos-duration="2000">
              <div class="questions-tittle mb-3">
                <span *ngIf="isSpanish">¿Debo ser familiar de la persona fallecida?</span>
                <span *ngIf="!isSpanish">¿Do I have to be a relative of the deceased?</span>
              </div>
              <p *ngIf="isSpanish" class="p-left">El reporte podra ser solicitado por la persona que presente la documentacion.
                En algunas entidades y dependiendo la gestion que el usuario desee realizar se puede llegar a requerir
                ser
                familiar directo.
                A eso te lo informaremos en el reporte.</p>
                <p *ngIf="!isSpanish" class="p-left">The report may be requested by the person presenting the documentation.
                   In some entities and depending on the management that the user wishes to make, it may be required to be a direct 
                   relative. We will inform you of this in the report.</p>
            </div>
          </div>
        </div>
        <div class="col-md-12">

          <div class="row ml-5">
            <div class="col-12 col-md-4 offset-md-1" data-aos="zoom-in-right" data-aos-duration="2000">
              <div class="questions-tittle mb-3">
                <span *ngIf="isSpanish">¿En que tiempo obtengo el reporte?</span>
                <span *ngIf="!isSpanish">¿How long does it take to get the report?</span>
              </div>
              <p *ngIf="isSpanish" class="p-left">Una vez recibida la documentación el reporte será entregado al usuario en 4 días
                hábiles. Con el reporte el usuario podrá decidir el accionar en cada caso.
              </p>
              <p *ngIf="!isSpanish" class="p-left">Once we receive the documentation the report will be delivered to the user in 4 
                working days. With the report the user will be able to decide what to do in each case.
              </p>
            </div>
            <div class="col-12 col-md-4 offset-md-1" data-aos="zoom-in-left" data-aos-duration="2000">
              <div class="questions-tittle mb-3">
                <span *ngIf="isSpanish">Beneficios que te corresponden si sos familiar directo.</span>
                <span *ngIf="!isSpanish">Benefits that correspond to you if you are a direct family member.</span>
              </div>
              <p *ngIf="isSpanish" class="p-left">Te acompañamos en este momento que vas a atravesar
                como familiar de la persona fallecida, brindándote información actualizada
                sobre los beneficios económicos estatales a los cuales podes acceder.
              </p>
              <p *ngIf="!isSpanish" class="p-left">We accompany you in this moment that you will go 
                through as a relative of the deceased, providing you with updated information 
                on the state economic benefits to which you can access.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row ml-5">
            <div class="col-12 col-md-4 offset-md-1" data-aos="zoom-in-right" data-aos-duration="2000">
              <div class="questions-tittle mb-3">
                <span *ngIf="isSpanish">¿Qué demora tiene el servicio?</span>
                <span *ngIf="!isSpanish">¿What is the delay in the service?</span>
              </div>
              <p *ngIf="isSpanish" class="p-left">El informe nos lleva entre 48 y 72 hs. dependiendo de la respuesta de las empresas que
                contactamos.
                Los trámites pueden llevar desde 24 horas hasta una semana.
              </p>
              <p *ngIf="!isSpanish" class="p-left">The report takes between 48 and 72 hours, depending on the response of the companies we 
                contact. The procedures can take from 24 hours to one week.
              </p>
            </div>
            <div class="col-12 col-md-4 offset-md-1" data-aos="zoom-in-left" data-aos-duration="2000">
              <div class="questions-tittle mb-3">
                <span *ngIf="isSpanish">¿Cómo puedo pagar?</span>
                <span *ngIf="!isSpanish">¿How can I pay?</span>
              </div>
              <p *ngIf="isSpanish" class="p-left">Podés pagar con tarjeta de débito, crédito y efectivo en Rapipago y pago fácil. Realizás
                todos los pagos en nuestra plataforma de forma segura y confiable.
              </p>
              <p *ngIf="!isSpanish" class="p-left">You can pay by debit card, credit card and cash at Rapipago and pay easily. You make 
                all payments on our platform safely and reliably.
              </p>
            </div>
          </div>
        </div>

        <div style="margin-left: 30px;">
        </div>
      </div>
      <div class="questions-items" style="display:none;">
        <ul class="text-left text-items-mobile">
          <li><a (click)="showQuestion(1)">
            <span *ngIf="isSpanish">¿Que documentacion voy a necesitar?</span>
            <span *ngIf="!isSpanish">¿What documentation will I need?</span>
          </a></li>
          <p class="question-text" *ngIf="wichQuestion == 1 && isShowing && isSpanish">Precisás presentar la partida de defunción de
            ser querido. Este papel
            es indispensable para empezar el informe. Dependiendo del resultado puede que solicitemos documentación
            adicional, pero no suele ser
            necesario.</p>
            <p class="question-text" *ngIf="wichQuestion == 1 && isShowing && !isSpanish ">You need to present the death certificate of your loved one. This paper is indispensable 
              to start the report. Depending on the outcome, we may request additional documentation, but it is not usually necessary.</p>
          <li><a (click)="showQuestion(2)">
            <span *ngIf="isSpanish">¿Debo ser familiar de la persona fallecida?</span>
            <span *ngIf="!isSpanish">¿Do I have to be a relative of the deceased?</span>
          </a></li>
          <p class="question-text" *ngIf="wichQuestion == 2 && isShowing && isSpanish">El reporte podra ser solicitado por la persona
            que presente la
            documentacion. En algunas entidades y dependiendo la gestion que el usuario desee realizar se puede llegar a
            requerir ser familiar
            directo. A eso te lo informaremos en el reporte.</p>
            <p class="question-text" *ngIf="wichQuestion == 2 && isShowing && !isSpanish">The report may be requested by the person presenting the documentation.
              In some entities and depending on the management that the user wishes to make, it may be required to be a direct 
              relative. We will inform you of this in the report.</p>
          <li><a (click)="showQuestion(3)">
            <span *ngIf="isSpanish">¿En que tiempo obtengo el reporte?</span>
            <span *ngIf="!isSpanish">¿How long does it take to get the report?</span>
          </a></li>
          <p class="question-text" *ngIf="wichQuestion == 3 && isShowing && isSpanish">Una vez recibida la documentación el reporte
            será entregado al usuario en 4 días hábiles. Con el reporte el usuario podrá decidir el accionar en cada
            caso.</p>
            <p class="question-text" *ngIf="wichQuestion == 3 && isShowing && !isSpanish">Once we receive the documentation the 
              report will be delivered to the user in 4 
              working days. With the report the user will be able to decide what to do in each case.</p>
          <li><a (click)="showQuestion(4)">
            <span *ngIf="isSpanish">¿Qué demora tiene el servicio?</span>
            <span *ngIf="!isSpanish">¿What is the delay in the service?</span>
          </a></li>
          <p class="question-text" *ngIf="wichQuestion == 4  && isShowing && isSpanish">El informe nos lleva entre 48 y 72 hs.
            dependiendo de la respuesta de
            las empresas que contactamos. Los trámites pueden llevar desde 24 horas hasta una semana.</p>
            <p class="question-text" *ngIf="wichQuestion == 4  && isShowing && !isSpanish">The report takes between 48 and 72 hours, depending on the response of the companies we 
              contact. The procedures can take from 24 hours to one week.</p>
          <li><a (click)="showQuestion(5)">
            <span *ngIf="isSpanish">¿Cómo puedo pagar?</span>
            <span *ngIf="!isSpanish">¿How can I pay?</span>
          </a></li>
          <p class="question-text" *ngIf="wichQuestion == 5 && isShowing && isSpanish">Podés pagar con tarjeta de débito, crédito y
            efectivo en Rapipago y pago
            fácil. Realizás todos los pagos en nuestra plataforma de forma segura y confiable.</p>
            <p class="question-text" *ngIf="wichQuestion == 5 && isShowing && !isSpanish">You can pay by debit card, credit card and 
              cash at Rapipago and pay easily. You make 
              all payments on our platform safely and reliably.</p>
          <li><a (click)="showQuestion(6)">
            <span *ngIf="isSpanish">Beneficios que te corresponden si sos familiar directo.</span>
            <span *ngIf="!isSpanish">Benefits that correspond to you if you are a direct family member.</span>
          </a></li>
          <p class="question-text" *ngIf="wichQuestion == 6 && isShowing && isSpanish">Te acompañamos en este momento que vas a
            atravesar como familiar de la persona fallecida, brindándote información actualizada sobre los beneficios
            económicos estatales a los cuales podes acceder.</p>
            <p class="question-text" *ngIf="wichQuestion == 6 && isShowing && !isSpanish">We accompany you in this moment that you will go 
              through as a relative of the deceased, providing you with updated information 
              on the state economic benefits to which you can access.</p>
        </ul>
      </div>
    </div>
    <div class="mt-5 pt-5 bg-grey need-help-back">
      <div class="col-md-8 offset-md-2" data-aos-duration="600" data-aos="fade-down" data-aos-delay="200">
        <div class="col-8 offset-2 pt-5 mb-4">
          <span *ngIf="isSpanish" class="top-text-why bg-grey">¿NECESITÁS AYUDA?</span>
          <span *ngIf="!isSpanish" class="top-text-why bg-grey">¿DO YOU NEED HELP?</span>
        </div>
        <h1 *ngIf="isSpanish" class="pb-5 mb-0">Artículos e información útil</h1>
        <h1 *ngIf="!isSpanish" class="pb-5 mb-0">Articles and useful information</h1>
      </div>
    </div>
    <div class="bg-grey">
      <div class="col-11 offset-1 col-md-10 offset-md-1 bg-grey">
        <div class="row">

          <div class="col-11  col-md-6 offset-md-0">
            <div class="co-12 col-md-6 show-on-mobile pl-0"
              style="display:table-cell; vertical-align:middle; text-align:center; display:none;">
              <img src="../../assets/images/image-section.jpeg" style="width: 112%;">
            </div>
            <div class="top-text-left" data-aos-duration="600" data-aos="fade-down" data-aos-delay="500">
              <span *ngIf="isSpanish">PRIMEROS PASOS</span>
              <span *ngIf="!isSpanish">FIRST STEPS</span>
            </div>
            <h1 *ngIf="isSpanish" class="mb-3 text-left mt-3" data-aos-duration="600" data-aos="fade-down" data-aos-delay="500">¿Qué
              trámites tenés que hacer si un familiar falleció?</h1>
              <h1 *ngIf="!isSpanish" class="mb-3 text-left mt-3" data-aos-duration="600" data-aos="fade-down" data-aos-delay="500">¿What do you do if a family member has died?</h1>
            <p *ngIf="isSpanish" class="text-left" data-aos-duration="600" data-aos="fade-down" data-aos-delay="500">Los trámites
              iniciales pueden variar un poco dependiendo de la causa de fallecimiento.
              Para la gran mayoría de los casos, lo primero con lo que deberías contar es con el “acta de defunción.”
            </p>
            <p *ngIf="!isSpanish" class="text-left" data-aos-duration="600" data-aos="fade-down" data-aos-delay="500">The initial procedures may vary somewhat depending on the cause
               of death. For the vast majority of cases, the first thing you should have is the "death certificate." 
            </p>
            <div class="div-article" style="text-align: left;">
              <a *ngIf="isSpanish" class="a-hover" href="https://medium.com/@magalimazzieri" target="_blank">Leer el artículo</a>
              <a *ngIf="!isSpanish"class="a-hover" href="https://medium.com/@magalimazzieri" target="_blank" style="font-size:17px;">Read the article</a>

              <img class="icon mr-3 mb-1" src="../../assets/icons/arrow.png" stlye="margin-top: 50px;">
            </div>
          </div>
          <div class="co-12 col-md-6 hide-on-mobile"
            style="display:table-cell; vertical-align:middle; text-align:center">
            <div style="margin-left: 30px;" class="div-mobile">
              <img src="../../assets/images/image-section.jpeg" style="width: 80%;" class="img-mobile">
            </div>
          </div>
        </div>
      </div>

      <div class="col-11 offset-1 col-md-10 offset-md-1 bg-grey pt-5 pb-5 section-mobile">
        <div class="row mt-4">

          <div class="col-12  offset-md-0 col-md-4" data-aos-duration="600" data-aos="fade-down" data-aos-delay="500">
            <div class="section-text mb-3">
              <span *ngIf="isSpanish">¿Por qué debe iniciarse el proceso sucesorio?</span>
              <span *ngIf="!isSpanish">¿Why should the succession process be initiated?</span>
            </div>
            <p *ngIf="isSpanish" class="section-description mb-0">Conforme lo dispone el artículo 2335 del Código Civil y Comercial de la
              Nación, el proceso sucesorio
              tiene por objeto identificar a los sucesores, determinar el contenido de la herencia,
              cobrar los créditos, pagar las deudas...
            </p>
            <p *ngIf="!isSpanish" class="section-description mb-0">According to article 2335 of the Argentine Civil and Commercial Code, 
              the purpose of the succession process is to identify the heirs, determine the content of the inheritance, collect the credits...
            </p>
            <div class="div-article" style="text-align: left;">
              <a *ngIf="isSpanish" class="a-hover"
                href="https://medium.com/@magalimazzieri/porque-debe-iniciarse-el-proceso-sucesorio-5bee149e054e"
                target="_blank">Leer el artículo</a>
                <a *ngIf="!isSpanish" class="a-hover"
                href="https://medium.com/@magalimazzieri/porque-debe-iniciarse-el-proceso-sucesorio-5bee149e054e"
                target="_blank" style="font-size:16px;">Read the article</a>
              <img class="icon mr-3 mb-1" src="../../assets/icons/arrow.png">
            </div>
          </div>

          <div class="col-12 col-md-4" data-aos-duration="600" data-aos="fade-down" data-aos-delay="500">
            <div class="section-text mb-3">
              <span *ngIf="isSpanish">Retomar el trabajo luego del fallecimiento de un familiar</span>
              <span *ngIf="!isSpanish">Returning to work after the death of a family member</span>
            </div>
            <p *ngIf="isSpanish" class="section-description mb-0">Cuando una persona tiene un problema emocional derivado de la pérdida de
              un ser querido, regresar
              al lugar de trabajo o retomar la rutina no suele ser fácil en algunos casos. Si se tiene la posibilidad de
              decidir... </p>
              <p *ngIf="!isSpanish" class="section-description mb-0">When a person has an emotional problem stemming from the loss of a 
                loved one, returning to the workplace or resuming a routine is often not easy in some cases. If you can decide...</p>
            <div class="div-article" style="text-align: left;">
              <a *ngIf="isSpanish" class="a-hover"
                href="https://medium.com/@magalimazzieri/retomar-el-trabajo-luego-del-fallecimiento-de-un-familiar-d1afe5086bb3"
                target="_blank">Leer el artículo</a>
                <a *ngIf="!isSpanish" class="a-hover"
                href="https://medium.com/@magalimazzieri/retomar-el-trabajo-luego-del-fallecimiento-de-un-familiar-d1afe5086bb3"
                target="_blank" style="font-size:16px;">Read the article</a>
              <img class="icon mr-3 mb-1" src="../../assets/icons/arrow.png">
            </div>

          </div>
          <div class="col-12 col-md-4" data-aos-duration="600" data-aos="fade-down" data-aos-delay="500">
            <div class="section-text mb-3">
              <span *ngIf="isSpanish">¿Cómo contarle a los niños este momento difícil?</span>
              <span *ngIf="!isSpanish">¿How do you tell children about this difficult time?</span>
            </div>
            <p *ngIf="isSpanish" class="section-description mb-0">En primer lugar, es importante mencionar brevemente las cinco etapas
              relacionadas a la reacción
              por la muerte de un ser querido: negación y aislamiento, rabia, negociación,
              depresión y por último la aceptación.</p>
              <p *ngIf="!isSpanish" class="section-description mb-0">First, it is important to briefly mention the five stages involved
                 in reacting to the death of a loved one: denial and isolation, anger, negotiation, depression, and finally acceptance.</p>
            <div class="div-article" style="text-align: left;">
              <a *ngIf="isSpanish" class="a-hover"
                href="https://medium.com/@magalimazzieri/c%C3%B3mo-contarle-a-los-ni%C3%B1os-este-momento-dif%C3%ADcil-416b71cf9dca"
                target="_blank">Leer el artículo</a>
                <a *ngIf="!isSpanish" class="a-hover"
                href="https://medium.com/@magalimazzieri/c%C3%B3mo-contarle-a-los-ni%C3%B1os-este-momento-dif%C3%ADcil-416b71cf9dca"
                target="_blank" style="font-size:16px;">Read the article</a>
              <img class="icon mr-3 mb-1" src="../../assets/icons/arrow.png">
            </div>
          </div>

        </div>
      </div>
      <div class="top-text" data-aos-duration="600" data-aos="fade-down" data-aos-delay="500">
        <span *ngIf="isSpanish">Tenés otros 12 artículos para ver</span>
        <span *ngIf="!isSpanish">You have 12 other items to see</span>
      </div>
      <div class="col-6 offset-3 col-md-2 offset-md-5" data-aos-duration="600" data-aos="fade-down"
        data-aos-delay="200">
        <div class="row row-button pt-2 no-margin">
          <button class="btn-section" onclick=" window.open('https://medium.com/@magalimazzieri','_blank')">
            <span *ngIf="isSpanish"> VER TODOS</span>
            <span *ngIf="!isSpanish"> SEE ALL</span>
          </button></div>
      </div>
    </div>
  </div>


  <div class="last-section">
    <div class="col-10 offset-1 col-md-8 offset-md-2 mt-4" data-aos-duration="600" data-aos="fade-down"
      data-aos-duration="3000">
      <h3 *ngIf="isSpanish">Disfrutá de tu tiempo donde más lo necesitas</h3>
      <h3 *ngIf="!isSpanish">Enjoy your time where you need it most</h3>
      <p *ngIf="isSpanish" class="text-center mt-3 col-md-8 offset-md-2 last-section-desc">Sacáte de encima el peso de hacer trámites
        engorrosos en un momento en donde necesitás estar
        con tu familia. </p>
        <p *ngIf="!isSpanish" class="text-center mt-3 col-md-8 offset-md-2 last-section-desc">Take the burden of doing cumbersome paperwork 
          off your shoulders at a time when you need to be with your family.</p>
      <div class="col-8 offset-2 col-md-4 offset-md-4">
        <button onclick="location.href = 'https://poscalm.com/sign-in'" class="contact-2 mt-3">
          <span *ngIf="isSpanish">EMPEZAR AHORA</span>
          <span *ngIf="!isSpanish">START NOW</span>
        </button>
      </div>
    </div>
  </div>
</main>